<template>
  <div :class="['flex justify-center', bgClass]">
    <div class="w-full px-4 md:px-6 lg:px-8 max-w-7xl mx-auto">
      <div class="py-16 md:py-24">
        <!-- Header Section -->
        <div class="max-w-3xl mx-auto text-center mb-16">
          <h2 :class="['text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mb-4', textClass]">Frequently Asked Questions</h2>
          <p :class="['text-lg opacity-90', textClass]">Find answers to common questions about our services</p>
        </div>

        <!-- FAQ Items -->
        <div class="max-w-3xl mx-auto">
          <div v-for="(faq, index) in faqs" :key="index" class="mb-4 last:mb-0">
            <div
              :class="[
                'bg-white rounded-xl shadow-sm transition-all duration-200',
                selectedFaq === index ? 'ring-2 ring-brand-black/10' : 'hover:bg-gray-50',
              ]"
            >
              <!-- Question Header -->
              <button
                class="w-full px-6 py-5 flex items-center justify-between text-left"
                @click="toggleFaq(index)"
                :aria-expanded="selectedFaq === index"
                :aria-controls="'faq-' + index"
              >
                <h3 class="text-lg font-semibold text-gray-900 pr-8">{{ faq.title }}</h3>
                <span class="flex-shrink-0 ml-2">
                  <svg
                    :class="['w-5 h-5 transition-transform duration-200', selectedFaq === index ? 'transform rotate-180' : '']"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </span>
              </button>

              <!-- Answer Content -->
              <div v-show="selectedFaq === index" :id="'faq-' + index" class="px-6 pb-5">
                <div class="prose prose-sm max-w-none text-gray-600" v-html="faq.desc"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  faqs: { type: Array, default: () => [] },
  bgClass: { type: String, default: 'bg-brand-black' },
  textClass: { type: String, default: 'text-white' },
});

const selectedFaq = ref(null);

const toggleFaq = (index) => {
  selectedFaq.value = selectedFaq.value === index ? null : index;
};
</script>

<style scoped>
.prose :deep(p) {
  margin: 0;
  line-height: 1.6;
}

.prose :deep(a) {
  color: #000;
  text-decoration: underline;
  font-weight: 500;
}

.prose :deep(ul),
.prose :deep(ol) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
</style>
