<template>
  <div class="max-w-4xl mx-auto px-4 py-12">
    <div v-if="isLoading" class="flex justify-center items-center min-h-[50vh]">
      <div class="animate-spin rounded-full h-12 w-12 border-4 border-brand-spot border-t-transparent"></div>
    </div>

    <div v-else-if="error" class="text-center text-red-500">
      {{ error }}
    </div>

    <template v-else>
      <article class="prose prose-lg max-w-none">
        <!-- Header -->
        <header class="mb-12">
          <h1 class="text-4xl font-bold mb-6 text-gray-900">{{ post.title }}</h1>
          <div class="flex flex-wrap gap-4 items-center justify-between mb-8">
            <div class="flex items-center text-gray-600">
              <img
                :src="post.author?.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(post.author?.name || 'Anonymous')}`"
                :alt="post.author?.name"
                class="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <div class="font-medium">{{ post.author?.name || 'Anonymous' }}</div>
                <div class="text-sm">{{ formatDate(post.publishDate) }}</div>
              </div>
            </div>

            <!-- Share Buttons -->
            <ShareButtons :title="post.title" :url="currentUrl" class="horizontal" />
          </div>
        </header>

        <!-- Content -->
        <div class="rich-text-content" v-html="renderRichText(post.content)"></div>

        <!-- Bottom Share Section -->
        <div class="border-t border-gray-200 mt-12 pt-8">
          <div class="flex flex-wrap gap-4 items-center justify-between">
            <div class="text-gray-600">Share this article:</div>
            <ShareButtons :title="post.title" :url="currentUrl" class="horizontal" />
          </div>
        </div>

        <!-- Related Posts -->
        <RelatedPosts :current-post="post" :all-posts="allPosts" />
      </article>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { getBlogPosts } from '@/services/contentful';
import ShareButtons from '@/components/blog/ShareButtons.vue';
import RelatedPosts from '@/components/blog/RelatedPosts.vue';

const route = useRoute();
const post = ref(null);
const allPosts = ref([]);
const isLoading = ref(true);
const error = ref(null);

const currentUrl = computed(() => {
  return `${window.location.origin}/blog/${route.params.slug}`;
});

const fetchBlog = async () => {
  try {
    const posts = await getBlogPosts();
    allPosts.value = posts;
    post.value = posts.find((p) => p.slug === route.params.slug);

    if (!post.value) {
      error.value = 'Blog post not found';
    }
  } catch (err) {
    console.error('Error fetching blog:', err);
    error.value = 'Failed to load blog post';
  } finally {
    isLoading.value = false;
  }
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { url, fileName } = node.data.target.fields.file;
      return `<img src="${url}" alt="${fileName}" class="w-full rounded-lg my-8" />`;
    },
    [BLOCKS.PARAGRAPH]: (node, next) => `<p class="mb-6 leading-relaxed text-gray-700">${next(node.content)}</p>`,
    [BLOCKS.HEADING_1]: (node, next) => `<h1 class="text-4xl font-bold mb-6 text-gray-900">${next(node.content)}</h1>`,
    [BLOCKS.HEADING_2]: (node, next) => `<h2 class="text-3xl font-bold mb-4 mt-8 text-gray-900">${next(node.content)}</h2>`,
    [BLOCKS.HEADING_3]: (node, next) => `<h3 class="text-2xl font-bold mb-4 mt-6 text-gray-900">${next(node.content)}</h3>`,
    [BLOCKS.UL_LIST]: (node, next) => `<ul class="list-disc pl-6 mb-6 space-y-2 text-gray-700">${next(node.content)}</ul>`,
    [BLOCKS.OL_LIST]: (node, next) => `<ol class="list-decimal pl-6 mb-6 space-y-2 text-gray-700">${next(node.content)}</ol>`,
    [BLOCKS.LIST_ITEM]: (node, next) => `<li>${next(node.content)}</li>`,
    [BLOCKS.QUOTE]: (node, next) =>
      `<blockquote class="border-l-4 border-brand-spot pl-4 italic my-6 text-gray-700">${next(node.content)}</blockquote>`,
    [INLINES.HYPERLINK]: (node, next) =>
      `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer" class="text-brand-spot hover:underline">${next(node.content)}</a>`,
  },
  renderMark: {
    [MARKS.BOLD]: (text) => `<strong class="font-bold">${text}</strong>`,
    [MARKS.ITALIC]: (text) => `<em class="italic">${text}</em>`,
    [MARKS.UNDERLINE]: (text) => `<u>${text}</u>`,
    [MARKS.CODE]: (text) => `<code class="bg-gray-100 rounded px-1 py-0.5 font-mono text-sm">${text}</code>`,
  },
};

const renderRichText = (content) => {
  if (!content) return '';
  return documentToHtmlString(content, renderOptions);
};

onMounted(fetchBlog);
</script>

<style>
.rich-text-content :deep(img) {
  @apply rounded-lg my-8;
}

.rich-text-content :deep(a) {
  @apply text-brand-spot hover:underline;
}

.rich-text-content :deep(pre) {
  @apply bg-gray-100 rounded-lg p-4 my-6 overflow-x-auto;
}
</style>
