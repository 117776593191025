<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <!-- Header Section -->
      <div class="max-w-4xl mx-auto text-center mb-16">
        <h2 class="text-3xl md:text-4xl font-bold text-brand-black mb-2">Affordable Pricing for Everyone</h2>
        <p class="text-lg md:text-lg text-gray-600/90 leading-relaxed">
          Unlock your career potential with a one-time payment for our personalized resume writing service - no recurring fees, cancel anytime.
        </p>
      </div>

      <!-- Pricing Cards Grid -->
      <div class="grid md:grid-cols-3 gap-8">
        <div
          v-for="data in pricingData"
          :key="data.id"
          class="bg-white border border-gray-200 rounded-2xl shadow-sm hover:shadow-lg transition-shadow duration-300 flex flex-col"
        >
          <!-- Card Header -->
          <div class="p-8 rounded-t-2xl" style="background: linear-gradient(135deg, #111827 0%, #1f2937 100%)">
            <h3 class="text-xl font-semibold text-white mb-2 min-h-[56px]">{{ data.type }}</h3>
            <p class="text-gray-300 text-sm min-h-[72px]">{{ data.details }}</p>
            <div class="mt-6">
              <span class="text-3xl font-bold text-white">{{ data.price }}</span>
            </div>
          </div>

          <!-- Card Content -->
          <div class="p-8 flex-grow flex flex-col">
            <div class="flex-grow">
              <h4 class="text-lg font-semibold text-gray-900 mb-4 h-[28px]">All the benefits include:</h4>
              <ul class="space-y-3">
                <li v-for="benefit in data.benefits" :key="benefit" class="flex items-start min-h-[48px]">
                  <svg class="h-5 w-5 text-brand-black flex-shrink-0 mr-3 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="text-gray-600">{{ benefit }}</span>
                </li>
              </ul>
            </div>

            <!-- CTA Button -->
            <div class="mt-8">
              <a
                :href="data.link"
                target="_blank"
                class="block w-full px-6 py-4 text-center text-white bg-brand-black rounded-xl font-semibold hover:bg-brand-black/90 transition-colors duration-300"
              >
                Get this
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      benefits: [
        "Formatted for success - Formatting that will get an employer's attention.",
        'Keyword optimized - Your CV will be optimized to pass through Applicant Tracking Systems.',
        '60 Hours Turn around',
        '1 Professionally Written Resume in editable Word & PDF Document',
        'CV Review With Optimization Score',
      ],
      users: [
        { name: 'Foo Bar', email: 'foo@bar.com' },
        { name: 'John Doh', email: 'john@doh.com' },
      ],
      pricingData: [
        {
          id: 0,
          type: 'Pro',
          details: 'For candidate with 3 - 7 years of working experience in their fields',
          price: 'NGN 20,000',
          link: 'https://paystack.com/buy/cv-writing-pro',
          benefits: [
            "Formatted for success - Formatting that will get an employer's attention.",
            'Keyword optimized - Your CV will be optimized to pass through Applicant Tracking Systems.',
            '5 working days after CV resource is shared',
            '1 Professionally Written Resume in editable Word & PDF Document',
          ],
        },
        {
          id: 1,
          type: 'Premium',
          details: 'For candidate with 8 - 15 years of working experience in their fields',
          price: 'NGN 30,000',
          link: 'https://paystack.com/buy/cv-writing-premium1',
          benefits: [
            '3 working days after CV resource is shared',
            "Formatted for success - Formatting that will get an employer's attention.",
            'Keyword optimized - Your CV will be optimized to pass through Applicant Tracking Systems.',
            '1 Professionally Written Resume in editable Word & PDF Documents',
          ],
        },
        {
          id: 2,
          type: 'Career Accelerator Kit “Get a job 3x faster”',
          details: 'For candidates with 0-3 years experience who want to accelerate their ability to access job opportunities.',
          price: 'NGN 30,000',
          link: 'https://paystack.com/buy/careeraccelerator-basic-bnehgf',
          benefits: [
            '5 working days after CV resource is shared',
            "Formatted for success - Formatting that will get an employer's attention.",
            'Keyword optimized - Your CV will be optimized to pass through Applicant Tracking Systems.',
            '1 Professionally Written Resume in editable Word & PDF Documents',
            'LinkedIn Profile Optimization',
            '2 sessions with a Career coach',
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.pricing {
  &:first-child,
  &:nth-child(5) {
    & > div {
      &:first-child {
        background-color: #060505;
      }
    }
  }

  &:nth-child(2) {
    & > div {
      &:first-child {
        background-color: #bd1531;

        p {
          color: #fff;
        }
      }
    }
  }

  &:nth-child(3) {
    & > div {
      &:first-child {
        background-color: #18a2e2;

        h2 {
          color: white;
        }

        p {
          color: white;
        }
      }
    }
  }

  &:nth-child(4) {
    & > div {
      &:first-child {
        background-color: #ff8803;

        h2 {
          color: white;
        }

        p {
          color: white;
        }
      }
    }
  }
}
</style>
