<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <div class="bg-[#FEFAF1] flex justify-center px-[24px] pt-[60px] md:px-20">
      <div class="w-full md:w-[76vw] flex justify-center">
        <div class="w-full">
          <div class="text-center pb-8">
            <h1 class="text-[#1A1A1A] text-[32px] md:text-[60px] font-semibold leading-[110%] helvetica mx-auto" v-html="heroTitle"></h1>
            <p class="mt-4 mb-8 text-[15px] md:text-[20px] leading-[150%] text-[#4A4A4A] font-normal max-w-2xl mx-auto" v-html="heroDesc"></p>
            <div class="gap-4 flex flex-col md:flex-row justify-center items-center">
              <a
                :href="heroBtnLink"
                class="px-8 py-4 bg-brand-black inline-block rounded-2xl text-white font-semibold hover:bg-brand-black/90 transition-colors duration-300"
                v-if="heroBtnText"
              >
                {{ heroBtnText }}
              </a>
              <a
                :href="heroBtnTwoLink"
                class="px-8 py-4 bg-white inline-block rounded-2xl text-brand-black font-bold border-2 border-brand-black hover:bg-brand-black hover:text-white transition-all duration-300"
                v-if="heroBtnTwoText"
              >
                {{ heroBtnTwoText }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { onMounted } from 'vue';
const props = defineProps({
  heroImg: { type: String },
  heroTitle: { type: String },
  heroDesc: { type: String },
  heroBtnText: { type: String },
  heroBtnLink: { type: String, default: '/contact' },
  heroBtnTwoText: { type: String },
  heroBtnTwoLink: { type: String, default: '/contact' },
  imageContainerClass: String,
  imageClass: { type: String },
});
</script>
<script>
export default {
  data() {
    return {
      showNav: false,
    };
  },
  //   methods: {
  //     beforeEnter(el) {
  //       el.style.opacity = '0';
  //       el.style.transform = 'translateY(-100px)';
  //     },
  //     // where the animation will end up
  //     enter(el) {
  //       gsap.to(el, {
  //         duration: 2.5,
  //         // delay: 1,
  //         y: 0,
  //         x: 0,
  //         opacity: 1,
  //         ease: "elastic.inOut(1,0.3)",
  //       });
  //     },
  //   },
};
</script>
