<template>
  <main class="container mx-auto">
    <div class="md:flex hidden items-center justify-between w-full gap-x-16" :class="reverse ? 'flex-row-reverse' : ''">
      <!-- Content Section -->
      <div class="w-[600px] flex flex-col">
        <!-- Tabs Container -->
        <div class="w-full">
          <div class="relative" v-for="(item, index) in details" :key="index">
            <div class="flex items-center">
              <div
                class="w-2 h-12 rounded-full transition-colors duration-500 mr-6"
                :class="current === item.id ? 'bg-brand-black' : 'bg-transparent'"
              ></div>
              <p class="text-[32px] transition-colors duration-500" :class="current === item.id ? 'text-[#1A1A1A] font-medium' : 'text-[#94A3B8]'">
                {{ item.text }}
              </p>
            </div>
            <hr class="my-6 border-gray-200 ml-8" />
          </div>
        </div>

        <!-- Content Container -->
        <div class="w-full flex-1">
          <transition name="fade" mode="out-in">
            <div :key="current" class="w-full">
              <div class="ml-8">
                <p class="text-[#475569] text-lg font-normal mb-8 leading-relaxed">
                  {{ details.find(item => item.id === current)?.description }}
                </p>
                <a
                  :href="details.find(item => item.id === current)?.link"
                  class="px-8 py-4 text-white inline-block rounded-xl bg-brand-black hover:bg-black transition-colors duration-300 font-semibold"
                >{{ details.find(item => item.id === current)?.btnText }}</a>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <!-- Image Section -->
      <div class="w-[600px] flex items-center">
        <div class="w-full relative" style="height: 600px">
          <transition name="fade" mode="out-in">
            <div :key="current" class="absolute inset-0 flex items-center justify-center">
              <img
                v-lazy="require(`@/assets/images/${details.find(item => item.id === current)?.img}`)"
                alt=""
                class="max-w-full max-h-full object-contain"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- Mobile View -->
    <div v-for="(item, index) in details" :key="index" class="pb-16 md:hidden">
      <div class="flex items-center">
        <div class="w-2 h-12 rounded-full mr-6 bg-brand-black"></div>
        <p class="text-3xl text-[#1A1A1A] font-medium">
          {{ item.text }}
        </p>
      </div>
      <img v-lazy="require(`@/assets/images/${item.img}`)" alt="image" class="my-6 w-full" />
      <div class="flex flex-col">
        <p class="text-[#475569] text-lg font-normal mb-8 leading-relaxed">{{ item.description }}</p>
        <a
          :href="item.link"
          class="px-8 py-4 text-white inline-block rounded-xl bg-brand-black hover:bg-black transition-colors duration-300 font-semibold text-center"
        >{{ item.btnText }}</a>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  details: { type: Array },
  reverse: { type: Boolean },
});

const current = ref(1);
let intervalId;

const switchSlide = () => {
  current.value = current.value >= props.details.length ? 1 : current.value + 1;
};

onMounted(() => {
  intervalId = setInterval(switchSlide, 5000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
