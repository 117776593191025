<template>
  <div class="min-h-screen bg-white">
    <div class="max-w-7xl mx-auto px-4 py-8">
      <div class="flex flex-col flex-wrap md:flex-row justify-between">
        <!-- Main Content (65%) -->
        <div class="md:w-[65%]">
          <!-- HR Guides Section -->
          <section class="mb-24">
            <div class="mb-6">
              <span class="text-sm text-brand-black uppercase tracking-wider">HUMAN RESOURCES GUIDES</span>
            </div>

            <template v-if="!isLoading">
              <!-- Main Featured Article -->
              <router-link
                v-if="postsByCategory['HR_GUIDES']?.length"
                :to="`/blog/${postsByCategory['HR_GUIDES'][0].slug}`"
                class="mb-16 cursor-pointer block"
              >
                <div v-if="postsByCategory['HR_GUIDES'][0].featuredImage" class="w-full aspect-[16/9] rounded-xl overflow-hidden mb-6">
                  <img
                    :src="postsByCategory['HR_GUIDES'][0].featuredImage"
                    :alt="postsByCategory['HR_GUIDES'][0].title"
                    class="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500"
                  />
                </div>
                <h2 class="text-3xl font-bold text-brand-black group-hover:text-brand-spot transition-colors mb-4">
                  {{ postsByCategory['HR_GUIDES'][0].title }}
                </h2>
                <div class="flex items-center text-sm text-light-grey">
                  <span>by {{ postsByCategory['HR_GUIDES'][0].author?.name }}</span>
                  <span class="mx-2">·</span>
                  <span>{{ formatDate(postsByCategory['HR_GUIDES'][0].publishDate) }}</span>
                </div>
              </router-link>

              <!-- Article Grid -->
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-12">
                <router-link
                  v-for="post in postsByCategory['HR_GUIDES']"
                  :key="post.id"
                  :to="`/blog/${post.slug}`"
                  class="cursor-pointer flex flex-col group min-h-[360px]"
                >
                  <div v-if="post.featuredImage" class="aspect-[4/3] rounded-xl overflow-hidden mb-4">
                    <img
                      :src="post.featuredImage"
                      :alt="post.title"
                      class="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                  <h3 class="text-base sm:text-lg font-medium mb-3 leading-tight line-clamp-2 group-hover:text-brand-spot transition-colors">
                    {{ post.title }}
                  </h3>
                  <div class="flex items-center text-sm text-light-grey mt-auto">
                    <span class="line-clamp-1">by {{ post.author?.name }}</span>
                    <span class="mx-2">·</span>
                    <span>{{ formatDate(post.publishDate) }}</span>
                  </div>
                </router-link>
              </div>
            </template>

            <!-- Skeleton Loader -->
            <BlogSkeleton v-else />
          </section>
        </div>

        <!-- Sidebar (30%) -->
        <div class="md:w-[30%]">
          <div class="mb-12">
            <h2 class="text-xl font-medium mb-8">LATEST</h2>

            <template v-if="!isLoading">
              <!-- Featured Latest Article -->
              <router-link v-if="latestPosts.length" :to="`/blog/${latestPosts[0].slug}`" class="mb-12 cursor-pointer">
                <div v-if="latestPosts[0].featuredImage" class="aspect-[16/9] rounded-xl overflow-hidden mb-4">
                  <img
                    :src="latestPosts[0].featuredImage"
                    :alt="latestPosts[0].title"
                    class="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                  />
                </div>
                <h3 class="text-xl font-medium mb-3 hover:text-brand-spot transition-colors">{{ latestPosts[0].title }}</h3>
                <p class="text-light-grey text-sm mb-3">{{ latestPosts[0].description }}</p>
                <div class="flex items-center text-sm text-light-grey">
                  <span>by {{ latestPosts[0].author?.name }}</span>
                  <span class="mx-2">·</span>
                  <span>{{ formatDate(latestPosts[0].publishDate) }}</span>
                </div>
              </router-link>

              <!-- Recent Articles List -->
              <div class="space-y-8">
                <router-link
                  v-for="post in latestPosts.slice(1)"
                  :key="post.id"
                  :to="`/blog/${post.slug}`"
                  class="flex gap-6 group cursor-pointer mt-10"
                >
                  <div v-if="post.featuredImage" class="w-24 h-24 rounded-lg overflow-hidden flex-shrink-0">
                    <img
                      :src="post.featuredImage"
                      :alt="post.title"
                      class="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                  <div>
                    <h3 class="font-medium mb-2 leading-snug group-hover:text-brand-spot transition-colors">{{ post.title }}</h3>
                    <div class="text-sm text-light-grey">by {{ post.author?.name }}</div>
                  </div>
                </router-link>
              </div>
            </template>

            <!-- Sidebar Skeleton -->
            <div v-else class="animate-pulse space-y-8">
              <div class="space-y-4">
                <div class="bg-gray-200 aspect-[16/9] rounded-xl"></div>
                <div class="h-6 bg-gray-200 rounded w-3/4"></div>
                <div class="h-4 bg-gray-200 rounded w-1/2"></div>
              </div>
              <div v-for="n in 3" :key="n" class="flex gap-4">
                <div class="w-24 h-24 bg-gray-200 rounded-lg flex-shrink-0"></div>
                <div class="flex-1 space-y-2">
                  <div class="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div class="h-4 bg-gray-200 rounded w-1/2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Full Width Category Section -->
      <div v-if="postsByCategory['RECRUITMENT'] && postsByCategory['RECRUITMENT'].length" class="mt-4 mb-20">
        <div class="max-w-7xl mx-auto">
          <div class="mb-12">
            <span class="text-sm text-brand-black uppercase tracking-wider font-medium bg-brand-primary-light px-4 py-2 rounded-full"
              >RECRUITMENT STRATEGIES</span
            >
          </div>

          <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
            <router-link
              v-for="(post, index) in postsByCategory['RECRUITMENT']"
              :key="post.id"
              :to="`/blog/${post.slug}`"
              class="group cursor-pointer flex items-start gap-6 p-6 rounded-xl hover:bg-white hover:shadow-xl transition-all duration-300"
            >
              <span class="text-5xl font-bold text-gray-200 group-hover:text-brand-spot transition-colors">{{ index + 1 }}</span>
              <div class="flex-1 pt-1">
                <h3 class="text-lg font-medium mb-2 text-brand-black group-hover:text-brand-spot transition-colors leading-tight">
                  {{ post.title }}
                </h3>
                <div class="flex items-center text-sm text-light-grey">
                  <img :src="post.author.avatar" :alt="post.author.name" class="w-5 h-5 rounded-full mr-2" />
                  <span>by {{ post.author.name }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- Changing Jobs Section -->
      <div v-if="postsByCategory['CAREER CHANGE'] && postsByCategory['CAREER CHANGE'].length" class="max-w-7xl mx-auto">
        <div class="mb-4 mt-32">
          <h2 class="text-sm text-brand-black uppercase tracking-wider font-medium bg-brand-primary-light px-4 py-2 rounded-full">CHANGING JOBS</h2>
          <div class="h-1 w-24 bg-brand-spot mt-4"></div>
        </div>

        <!-- Featured Post -->
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-16 mb-24">
          <router-link
            v-if="postsByCategory['CAREER CHANGE']?.length"
            :to="`/blog/${postsByCategory['CAREER CHANGE'][0].slug}`"
            class="relative group cursor-pointer"
          >
            <div v-if="postsByCategory['CAREER CHANGE'][0].featuredImage" class="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden">
              <img
                :src="postsByCategory['CAREER CHANGE'][0].featuredImage"
                :alt="postsByCategory['CAREER CHANGE'][0].title"
                class="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500"
              />
            </div>
            <div class="mt-8">
              <h3 class="text-2xl font-bold text-brand-black group-hover:text-brand-spot transition-colors">
                {{ postsByCategory['CAREER CHANGE'][0].title }}
              </h3>
              <p class="mt-4 text-light-grey line-clamp-3">
                {{ postsByCategory['CAREER CHANGE'][0].description }}
              </p>
              <div class="mt-6 flex items-center">
                <img
                  :src="
                    postsByCategory['CAREER CHANGE'][0].author?.avatar ||
                    'https://ui-avatars.com/api/?name=' + encodeURIComponent(postsByCategory['CAREER CHANGE'][0].author?.name || 'Anonymous')
                  "
                  :alt="postsByCategory['CAREER CHANGE'][0].author?.name"
                  class="h-10 w-10 rounded-full"
                />
                <div class="ml-3">
                  <p class="text-sm font-medium text-brand-black">{{ postsByCategory['CAREER CHANGE'][0].author?.name || 'Anonymous' }}</p>
                  <div class="flex items-center text-sm text-light-grey">
                    <span>{{ formatDate(postsByCategory['CAREER CHANGE'][0].publishDate) }}</span>
                    <span class="mx-2">•</span>
                    <span>{{ calculateReadTime(postsByCategory['CAREER CHANGE'][0].content) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </router-link>

          <!-- Side Articles -->
          <div class="space-y-12">
            <router-link
              v-for="post in postsByCategory['CAREER CHANGE'].slice(1, 4)"
              :key="post.id"
              :to="`/blog/${post.slug}`"
              class="group cursor-pointer"
            >
              <div class="flex gap-8">
                <div class="w-2/5">
                  <div v-if="post.featuredImage" class="aspect-w-4 aspect-h-3 rounded-xl overflow-hidden">
                    <img
                      :src="post.featuredImage"
                      :alt="post.title"
                      class="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                </div>
                <div class="w-3/5">
                  <h3 class="mt-2 text-xl font-bold text-brand-black group-hover:text-brand-spot transition-colors">
                    {{ post.title }}
                  </h3>
                  <div class="mt-4 flex items-center text-sm text-light-grey">
                    <span>{{ formatDate(post.publishDate) }}</span>
                    <span class="mx-2">•</span>
                    <span>{{ calculateReadTime(post.content) }}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- Spacer before Job Search section -->
      <div class="border-t border-light-grey2 my-24"></div>

      <!-- Job Search Section -->
      <div v-if="postsByCategory['JOB SEARCH'] && postsByCategory['JOB SEARCH'].length" class="max-w-7xl mx-auto mb-24">
        <div class="mb-4">
          <h2 class="text-sm font-bold text-brand-black">JOB SEARCH</h2>
          <div class="h-1 w-24 bg-brand-spot mt-4"></div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
          <router-link
            v-for="post in postsByCategory['JOB SEARCH'].slice(0, 3)"
            :key="post.id"
            :to="`/blog/${post.slug}`"
            class="group cursor-pointer"
          >
            <div v-if="post.featuredImage" class="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden mb-6">
              <img
                :src="post.featuredImage"
                :alt="post.title"
                class="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500"
              />
            </div>
            <h3 class="text-xl font-bold text-brand-black group-hover:text-brand-spot transition-colors">
              {{ post.title }}
            </h3>
            <div class="mt-4 flex items-center">
              <img
                :src="post.author?.avatar || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(post.author?.name || 'Anonymous')"
                :alt="post.author?.name"
                class="h-8 w-8 rounded-full"
              />
              <div class="ml-3 flex items-center text-sm text-light-grey">
                <span>{{ post.author?.name || 'Anonymous' }}</span>
                <span class="mx-2">•</span>
                <span>{{ formatDate(post.publishDate) }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- Resume Section -->
      <div v-if="postsByCategory['RESUME'] && postsByCategory['RESUME'].length" class="max-w-7xl mx-auto mb-32">
        <div class="mb-4">
          <h2 class="text-sm text-brand-black uppercase tracking-wider font-medium bg-brand-primary-light px-4 py-2 rounded-full inline-block">
            RESUMES
          </h2>
          <div class="h-1 w-24 bg-brand-spot mt-4"></div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
          <!-- Main Resume Articles -->
          <router-link v-for="post in postsByCategory['RESUME'].slice(0, 2)" :key="post.id" :to="`/blog/${post.slug}`" class="group cursor-pointer">
            <div v-if="post.featuredImage" class="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden mb-6">
              <img
                :src="post.featuredImage"
                :alt="post.title"
                class="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500"
              />
            </div>
            <h3 class="text-xl font-bold text-brand-black group-hover:text-brand-spot transition-colors mb-4">
              {{ post.title }}
            </h3>
            <p class="text-light-grey mb-4 line-clamp-2">
              {{ post.description }}
            </p>
            <div class="flex items-center text-sm text-light-grey">
              <div class="flex items-center">
                <span class="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-brand-spot text-white mr-3">AI</span>
                <span>by {{ post.author?.name }}</span>
                <span class="mx-2">•</span>
                <span>{{ formatDate(post.publishDate) }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- Career Stories Section -->
      <div v-if="postsByCategory['CAREER STORIES'] && postsByCategory['CAREER STORIES'].length" class="max-w-7xl mx-auto mb-32">
        <div class="mb-2 mt-20">
          <h2 class="text-sm text-brand-black uppercase tracking-wider font-medium bg-brand-primary-light px-4 py-2 rounded-full inline-block">
            CAREER STORIES
          </h2>
          <div class="h-1 w-24 bg-brand-spot mt-4"></div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <router-link
            v-for="post in postsByCategory['CAREER STORIES'].slice(0, 3)"
            :key="post.id"
            :to="`/blog/${post.slug}`"
            class="group cursor-pointer"
          >
            <div v-if="post.featuredImage" class="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden mb-6">
              <img
                :src="post.featuredImage"
                :alt="post.title"
                class="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500"
              />
            </div>
            <h3 class="text-xl font-bold text-brand-black group-hover:text-brand-spot transition-colors mb-4">
              {{ post.title }}
            </h3>
            <div class="flex items-center text-sm text-light-grey">
              <img
                :src="post.author?.avatar || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(post.author?.name || 'Anonymous')"
                :alt="post.author?.name"
                class="h-8 w-8 rounded-full"
              />
              <div class="ml-3 flex items-center">
                <span>by {{ post.author?.name }}</span>
                <span class="mx-2">•</span>
                <span>{{ formatDate(post.publishDate) }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- Skills & Careers in Tech Section -->
      <div v-if="postsByCategory['TECH CAREERS'] && postsByCategory['TECH CAREERS'].length" class="max-w-7xl mx-auto mb-32">
        <div class="mb-4 mt-10">
          <h2 class="text-sm text-brand-black uppercase tracking-wider font-medium bg-brand-primary-light px-4 py-2 rounded-full inline-block">
            SKILLS & CAREERS IN TECH
          </h2>
          <div class="h-1 w-24 bg-brand-spot mt-4"></div>
        </div>

        <!-- Featured Article -->
        <div v-if="getFeaturedPostsByCategory['TECH CAREERS']?.length" class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div class="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden">
            <img
              :src="getFeaturedPostsByCategory['TECH CAREERS'][0].featuredImage"
              :alt="getFeaturedPostsByCategory['TECH CAREERS'][0].title"
              class="object-cover w-full h-full"
            />
          </div>
          <div class="flex flex-col justify-center">
            <div class="mb-4">
              <span class="text-xs uppercase tracking-wider font-medium bg-brand-spot px-3 py-1.5 rounded-full text-white">#FEATURED</span>
            </div>
            <h3 class="text-2xl md:text-3xl font-bold text-brand-black mb-4 hover:text-brand-spot transition-colors">
              {{ getFeaturedPostsByCategory['TECH CAREERS'][0].title }}
            </h3>
            <p class="text-light-grey mb-6 line-clamp-3">
              {{ getFeaturedPostsByCategory['TECH CAREERS'][0].description }}
            </p>
            <div class="flex items-center text-sm text-light-grey">
              <img
                :src="getFeaturedPostsByCategory['TECH CAREERS'][0].author.avatar"
                :alt="getFeaturedPostsByCategory['TECH CAREERS'][0].author.name"
                class="h-8 w-8 rounded-full"
              />
              <div class="ml-3 flex items-center">
                <span>by {{ getFeaturedPostsByCategory['TECH CAREERS'][0].author.name }}</span>
                <span class="mx-2">•</span>
                <span>{{ formatDate(getFeaturedPostsByCategory['TECH CAREERS'][0].publishDate) }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Grid Articles -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <router-link
            v-for="post in postsByCategory['TECH CAREERS'].slice(1, 4)"
            :key="post.id"
            :to="`/blog/${post.slug}`"
            class="group cursor-pointer"
          >
            <div v-if="post.featuredImage" class="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden mb-4">
              <img
                :src="post.featuredImage"
                :alt="post.title"
                class="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500"
              />
            </div>
            <h3 class="text-xl font-bold text-brand-black group-hover:text-brand-spot transition-colors mb-3 line-clamp-2">
              {{ post.title }}
            </h3>
            <div class="flex items-center text-sm text-light-grey">
              <img :src="post.author.avatar" :alt="post.author.name" class="h-8 w-8 rounded-full" />
              <div class="ml-3 flex items-center">
                <span>by {{ post.author.name }}</span>
                <span class="mx-2">•</span>
                <span>{{ formatDate(post.publishDate) }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { getBlogPosts } from '@/services/contentful';
import BlogSkeleton from '@/components/BlogSkeleton.vue';

const blogPosts = ref([]);
const isLoading = ref(true);

// Organize posts by category
const postsByCategory = computed(() => {
  const grouped = {};
  blogPosts.value.forEach((post) => {
    const category = post.category.toUpperCase();
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push(post);
  });
  return grouped;
});

// Get featured posts for each category
const getFeaturedPostsByCategory = computed(() => {
  const featured = {};
  Object.keys(postsByCategory.value).forEach((category) => {
    featured[category] = postsByCategory.value[category].filter((post) => post.isFeatured);
  });
  return featured;
});

// Get latest posts for sidebar
const latestPosts = computed(() => {
  return [...blogPosts.value].sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate)).slice(0, 3);
});

// Format date helper
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

// Calculate read time (assuming 200 words per minute)
const calculateReadTime = (content) => {
  // if (!content) return '1 min read';
  // const words = content.trim().split(/\s+/).length;
  // const minutes = Math.max(1, Math.ceil(words / 200));
  // return `${minutes} min read`;
};

const fetchBlogPosts = async () => {
  try {
    const posts = await getBlogPosts();
    blogPosts.value = posts;
  } catch (error) {
    console.error('Error loading blog posts:', error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchBlogPosts);
</script>
