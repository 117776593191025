<!-- ShareButtons.vue -->
<template>
  <div :class="['flex gap-3', horizontal ? 'flex-row' : 'flex-row gap-2']">
    <button
      v-for="(social, index) in socials"
      :key="index"
      @click="share(social)"
      class="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-all duration-300 group"
      :title="`Share on ${social.platform}`"
    >
      <img :src="social.icon" :alt="social.platform" class="w-5 h-5 group-hover:scale-110 transition-transform" />
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
});

const socials = [
  {
    platform: 'Twitter',
    icon: '/icons/twitter.svg',
    getShareUrl: () => `https://twitter.com/intent/tweet?text=${encodeURIComponent(props.title)}&url=${encodeURIComponent(props.url)}`,
  },
  {
    platform: 'LinkedIn',
    icon: '/icons/linkedin.svg',
    getShareUrl: () => `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(props.url)}`,
  },
  {
    platform: 'Facebook',
    icon: '/icons/facebook.svg',
    getShareUrl: () => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.url)}`,
  },
  {
    platform: 'WhatsApp',
    icon: '/icons/whatsapp.svg',
    getShareUrl: () => `https://wa.me/?text=${encodeURIComponent(`${props.title} ${props.url}`)}`,
  },
];

const share = (social) => {
  const shareUrl = social.getShareUrl();
  window.open(shareUrl, '_blank', 'width=600,height=400');
};
</script>
