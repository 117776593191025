<template>
  <div class="relative bg-light-yellow py-32">
    <!-- Subtle gradient background -->
    <div class="absolute inset-0 bg-gradient-radial from-light-yellow to-[#FFF8E7]"></div>

    <div class="md:w-[82vw] mx-auto px-4 md:px-0 relative">
      <!-- Header section -->
      <div class="mb-20 max-w-3xl">
        <h2 class="text-3xl md:text-5xl font-bold text-brand-black mb-6">Who We Help</h2>
        <p class="text-gray-600 text-lg md:text-xl leading-relaxed">
          Going global? Entering new markets in Africa? The talent is here - but recruiting and managing employees abroad has major risks and
          roadblocks.
        </p>
      </div>

      <!-- Cards section -->
      <div class="bg-white rounded-3xl shadow-sm border border-gray-100 overflow-hidden">
        <!-- CEO Card -->
        <div class="group hover:bg-gray-50 transition-all duration-300">
          <div class="flex flex-col md:flex-row items-start md:items-center gap-8 p-8 md:p-12 border-b border-gray-100">
            <div class="md:w-1/3">
              <div class="flex items-center gap-4">
                <img src="@/assets/images/leadership.svg" class="w-12 h-12 md:w-16 md:h-16" alt="CEOs and Founders" />
                <h3 class="text-xl md:text-2xl font-semibold text-brand-black">CEOs and Founder</h3>
              </div>
            </div>
            <div class="md:w-2/3">
              <p class="text-gray-600 text-lg leading-relaxed">
                Hit the inquire button. Tell us about the role you're looking to fill. We'll get to know each other and the job you're looking to
                fill.
              </p>
            </div>
          </div>
        </div>

        <!-- HR Card -->
        <div class="group hover:bg-gray-50 transition-all duration-300">
          <div class="flex flex-col md:flex-row items-start md:items-center gap-8 p-8 md:p-12 border-b border-gray-100">
            <div class="md:w-1/3">
              <div class="flex items-center gap-4">
                <img src="@/assets/images/humanresearch.svg" class="w-12 h-12 md:w-16 md:h-16" alt="HR and Talent" />
                <h3 class="text-xl md:text-2xl font-semibold text-brand-black">HR and talent<br />Acquisition Leaders</h3>
              </div>
            </div>
            <div class="md:w-2/3">
              <p class="text-gray-600 text-lg leading-relaxed">
                At mid-market enterprises who are in need of a full-service partner with an efficient and streamlined hiring process, to accelerate
                their recruitment efforts while staying legally compliant.
              </p>
            </div>
          </div>
        </div>

        <!-- Executives Card -->
        <div class="group hover:bg-gray-50 transition-all duration-300">
          <div class="flex flex-col md:flex-row items-start md:items-center gap-8 p-8 md:p-12">
            <div class="md:w-1/3">
              <div class="flex items-center gap-4">
                <img src="@/assets/images/meetingroom.svg" class="w-12 h-12 md:w-16 md:h-16" alt="Executives" />
                <h3 class="text-xl md:text-2xl font-semibold text-brand-black">Executives</h3>
              </div>
            </div>
            <div class="md:w-2/3">
              <p class="text-gray-600 text-lg leading-relaxed">
                At rapidly expanding businesses looking to hire great talent without compromising on quality and reduce time to hire by up to 60%.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
