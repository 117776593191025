<template>
  <div class="p-6 rounded-lg bg-white" v-if="job?.employer">
    <div>
      <div
        class="bg-cover bg-center w-full h-[224px] bg-no-repeat rounded-lg overflow-hidden"
        v-lazy:background-image="job?.employer?.banner_url ? job?.employer?.banner_url : bgImage"
        style="background-size: 100% 100%"
      ></div>
    </div>
    <div>
      <div class="flex items-center my-6">
        <img v-lazy="job?.employer?.logo_url" class="w-[24px] h-[24px] mr-2" alt="" />
        <p class="text-brand-black text-[20px] leading-[110%]">{{ job?.employer?.name }}</p>
      </div>

      <div class="border-b border-[#F1F1F1] mb-4">
        <p class="text-brand-black text-[20px] leading-[28px] font-bold mb-1">{{ job?.role_title }}</p>
        <p class="text-[#64666C] leading-[22px] text-[14px] line-clamp-2 mb-6">
          Acquire valuable experience by supporting leaders to execute client engagements at a globally renowned firm, by asking the questions which
          unearth new answers to the complex
        </p>

        <div class="inline-flex items-center mb-6">
          <img v-lazy="locationIcon" class="sm:mr-2 w-[16px] h-[16px]" alt="" />
          <span class="text-[#64666C] text-[13px]" v-if="hasLocation(job)">{{ job?.state?.name }}, {{ job?.state?.country_code }}</span>
          <span class="text-[#64666C] text-[13px]" v-else>Anywhere</span>
        </div>

        <div class="block md:flex justify-between flex-wrap mb-2">
          <div class="inline-flex mb-4 mr-3 items-start gap-2">
            <span class="bg-[#F1F1F1] text-[12px] inline-block px-4 py-2 rounded-3xl capitalize">{{ job.type.split('_').join(' ') }}</span>
            <span class="bg-[#F1F1F1] text-[12px] inline-block px-4 py-2 rounded-3xl capitalize">{{ job.work_type.split('_').join(' ') }}</span>
          </div>

          <div class="w-full text-right md:w-auto">
            <router-link
              :to="`/${formatTitle(job?.employer?.name)}/${job?.fid}/${formatTitle(job?.role_title)}`"
              class="inline-block px-4 py-3 rounded-3xl bg-brand-black text-white mb-4"
              >More Details</router-link
            >
          </div>
        </div>
      </div>

      <div class="flex justify-between mb-2">
        <div class="flex">
          <!-- <img src="@/assets/icons/usd.svg" class="mr-2" alt="" /> -->
          <p class="font-bold text-[16px]" v-if="hasSalary(job?.salary_min)">
            {{ currencySymbol(job?.salary_currency) + formatNumber(job?.salary_min) }} /<span class="font-normal">{{
              job?.pay_period.split('_').join(' ')
            }}</span>
          </p>
          <p class="font-bold text-[16px]" v-else>
            <span class="font-normal">Salary Negotiable</span>
          </p>
        </div>

        <div class="flex items-center">
          <img v-lazy="calendarIcon" class="mr-2" alt="" />
          <p class="text-[14px] text-[#64666C] whitespace-nowrap">{{ getDifferenceInDays(job?.created_at) }} days ago</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { currencySymbol, formatNumber, getDifferenceInDays, formatTitle } from '@/utils/helpers';
import calendarIcon from '@/assets/icons/calendar.svg';
import locationIcon from '@/assets/icons/location.svg';
import bgImage from '@/assets/images/hiring.jpg';

const props = defineProps({
  job: { type: Object },
});

const hasSalary = (amount) => {
  const salary = amount ? parseInt(amount) : 0;
  if (salary < 1) return false;
  return true;
};

const hasLocation = (job) => {
  if (!job?.state?.name && !job?.state?.country) return false;
  return true;
};
</script>
