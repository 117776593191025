<template>
  <Hero
    heroTitle="Build high performing remote teams with handpicked, world-class talent"
    heroDesc="We are a 360 solution to find, hire, onboard, and retain top 1% highly skilled African talents"
    heroBtnText="Work with us"
    :heroBtnLink="$employerUrl"
    heroImg="hero3.png"
  />

  <Customers />

  <div class="relative bg-brand-black py-32">
    <!-- Subtle gradient background -->
    <div class="absolute inset-0 bg-gradient-radial from-brand-black to-[#1a1a1a]"></div>

    <div class="md:w-[82vw] mx-auto px-4 md:px-0 relative">
      <!-- Header section -->
      <div class="mb-20">
        <span class="text-brand-spot font-medium tracking-wide">TALENT SEARCH</span>
        <h2 class="text-3xl md:text-4xl font-bold text-white mt-6 leading-tight">Looking for exceptional talent?</h2>
      </div>

      <!-- Talent cards -->
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-20">
        <div class="group bg-[#1a1a1a] rounded-2xl p-6 transition-all duration-300 hover:bg-[#222]">
          <div class="text-[#FDE442] mb-4">
            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <h3 class="text-white font-medium mb-2">Sales Rep</h3>
          <p class="text-gray-400 text-sm">Drive Growth</p>
        </div>

        <div class="group bg-[#1a1a1a] rounded-2xl p-6 transition-all duration-300 hover:bg-[#222]">
          <div class="text-[#00F5D4] mb-4">
            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <h3 class="text-white font-medium mb-2">Support</h3>
          <p class="text-gray-400 text-sm">Help Users</p>
        </div>

        <div class="group bg-[#1a1a1a] rounded-2xl p-6 transition-all duration-300 hover:bg-[#222]">
          <div class="text-[#FE6D74] mb-4">
            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
              />
            </svg>
          </div>
          <h3 class="text-white font-medium mb-2">Designer</h3>
          <p class="text-gray-400 text-sm">Create Magic</p>
        </div>

        <div class="group bg-[#1a1a1a] rounded-2xl p-6 transition-all duration-300 hover:bg-[#222]">
          <div class="text-[#C7E8F9] mb-4">
            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          </div>
          <h3 class="text-white font-medium mb-2">Recruiter</h3>
          <p class="text-gray-400 text-sm">Build Teams</p>
        </div>

        <div class="group bg-[#1a1a1a] rounded-2xl p-6 transition-all duration-300 hover:bg-[#222]">
          <div class="text-purple-400 mb-4">
            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          </div>
          <h3 class="text-white font-medium mb-2">Writer</h3>
          <p class="text-gray-400 text-sm">Tell Stories</p>
        </div>
      </div>

      <!-- Bottom text -->
      <div class="text-center">
        <p class="text-gray-400 text-lg">Building your dream team shouldn't be a nightmare</p>
        <div class="mt-4 flex justify-center space-x-2">
          <span class="w-1.5 h-1.5 rounded-full bg-[#FDE442]"></span>
          <span class="w-1.5 h-1.5 rounded-full bg-[#00F5D4]"></span>
          <span class="w-1.5 h-1.5 rounded-full bg-[#FE6D74]"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="relative bg-brand-black py-32">
    <!-- Subtle gradient background -->
    <div class="absolute inset-0 bg-gradient-radial from-brand-black to-[#1a1a1a]"></div>

    <div class="md:w-[82vw] mx-auto px-4 md:px-0 relative">
      <!-- Header section -->
      <div class="mb-16 text-center">
        <h2 class="text-3xl md:text-4xl font-bold text-white">Hear what others are saying</h2>
      </div>

      <!-- Testimonials Grid -->
      <div class="grid md:grid-cols-2 gap-8">
        <!-- Testimonial 1 -->
        <div class="bg-[#1a1a1a] rounded-2xl p-8 transition-all duration-300 hover:bg-[#222]">
          <div class="flex items-center gap-4 mb-6">
            <img src="@/assets/images/testify3.jpeg" class="w-12 h-12 rounded-full object-cover" alt="Anu" />
            <div>
              <h3 class="text-white font-medium text-lg">Anu</h3>
              <p class="text-gray-400 text-sm">Founder - Sabi</p>
            </div>
          </div>
          <div class="relative">
            <svg class="absolute -top-4 -left-2 w-8 h-8 text-brand-spot/10" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"
              />
            </svg>
            <p class="text-gray-400 leading-relaxed relative">
              "CareerBuddy is the best-kept secret in the world of Startup recruiting without a doubt. We can't recommend them enough. We have tried a
              lot of recruiting partners even "high profile" ones but with little or no success. CareerBuddy makes recruitment look easy. They
              understand our business and they continue to help us with some of our most critical roles"
            </p>
          </div>
        </div>

        <!-- Testimonial 2 -->
        <div class="bg-[#1a1a1a] rounded-2xl p-8 transition-all duration-300 hover:bg-[#222]">
          <div class="flex items-center gap-4 mb-6">
            <img src="@/assets/images/testify4.jpeg" class="w-12 h-12 rounded-full object-cover" alt="Mudia" />
            <div>
              <h3 class="text-white font-medium text-lg">Mudia</h3>
              <p class="text-gray-400 text-sm">CEO/Founder - Matta</p>
            </div>
          </div>
          <div class="relative">
            <svg class="absolute -top-4 -left-2 w-8 h-8 text-brand-spot/10" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"
              />
            </svg>
            <p class="text-gray-400 leading-relaxed relative">
              When it comes to Start-up hiring, CareerBuddy is your go-to company. They understand the assignment and even exceed expectations. They
              take the time to elicit the requirements and find the right candidates for the roles. We're particularly relieved about the fact that we
              can trust CareerBuddy's pre-screening processes to be thorough. We'll obviously be repeat clients of CareerBuddy.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="relative bg-white py-32">
    <!-- Subtle gradient background -->
    <div class="absolute inset-0 bg-gradient-radial from-white to-gray-50/30"></div>

    <div class="md:w-[82vw] mx-auto px-4 md:px-0 relative">
      <!-- Header section -->
      <div class="mb-20 text-center max-w-3xl mx-auto">
        <h2 class="text-3xl md:text-5xl font-bold text-brand-black mb-6">How CareerBuddy helps you</h2>
        <p class="text-gray-600 text-lg md:text-xl">Use any of our talent services to grow your team without any of the headaches.</p>
      </div>

      <!-- Services Grid -->
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <!-- Head Hunting -->
        <div class="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100">
          <div class="bg-brand-black w-16 h-16 rounded-xl flex items-center justify-center mb-6">
            <img src="@/assets/icons/book.svg" alt="" class="w-8 h-8" />
          </div>

          <h3 class="text-xl md:text-2xl font-semibold text-brand-black mb-4">Head Hunting</h3>

          <p class="text-gray-600 mb-8 leading-relaxed">
            Use our Headhunting service and talent self service to fill your roles quickly. Save time and resources combing through hundreds of
            applications. Fill open positions in under 21 days.
          </p>

          <a
            href="/talent-placement"
            class="inline-flex items-center px-6 py-3 bg-brand-black text-white rounded-xl hover:bg-brand-black/90 transition-colors"
          >
            Start Hiring
            <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </div>

        <!-- Outsourcing -->
        <div class="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100">
          <div class="bg-brand-black w-16 h-16 rounded-xl flex items-center justify-center mb-6">
            <img src="@/assets/icons/book.svg" alt="" class="w-8 h-8" />
          </div>

          <h3 class="text-xl md:text-2xl font-semibold text-brand-black mb-4">Outsourcing</h3>

          <p class="text-gray-600 mb-8 leading-relaxed">
            We help you hire and manage your remote team. Manage payroll, handle everything from tax compliance to salary disbursements, ensuring your
            team is paid accurately and on time.
          </p>

          <a href="" class="inline-flex items-center px-6 py-3 bg-brand-black text-white rounded-xl hover:bg-brand-black/90 transition-colors">
            Tell me more
            <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </div>

        <!-- Embedded Recruiter -->
        <div class="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100">
          <div class="bg-brand-black w-16 h-16 rounded-xl flex items-center justify-center mb-6">
            <img src="@/assets/icons/book.svg" alt="" class="w-8 h-8" />
          </div>

          <h3 class="text-xl md:text-2xl font-semibold text-brand-black mb-4">Embedded Recruiter</h3>

          <p class="text-gray-600 mb-8 leading-relaxed">
            Eliminate hefty agency fees by transitioning to a subscription-based hiring model. Use our more cost-effective, predictable, and
            transparent method for managing your recruitment expenses.
          </p>

          <a href="" class="inline-flex items-center px-6 py-3 bg-brand-black text-white rounded-xl hover:bg-brand-black/90 transition-colors">
            Learn more
            <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </div>

        <!-- Employer Branding -->
        <div class="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100">
          <div class="bg-brand-black w-16 h-16 rounded-xl flex items-center justify-center mb-6">
            <img src="@/assets/icons/book.svg" alt="" class="w-8 h-8" />
          </div>

          <h3 class="text-xl md:text-2xl font-semibold text-brand-black mb-4">Employer Branding</h3>

          <p class="text-gray-600 mb-8 leading-relaxed">
            Elevate your employer branding to effectively reach and attract talent possessing the precise skills and competencies necessary for
            contributing to your organization's success.
          </p>

          <a href="" class="inline-flex items-center px-6 py-3 bg-brand-black text-white rounded-xl hover:bg-brand-black/90 transition-colors">
            Tell me more
            <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </div>

        <!-- Talent Marketplace -->
        <div class="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100">
          <div class="bg-brand-black w-16 h-16 rounded-xl flex items-center justify-center mb-6">
            <img src="@/assets/icons/book.svg" alt="" class="w-8 h-8" />
          </div>

          <h3 class="text-xl md:text-2xl font-semibold text-brand-black mb-4">Talent Marketplace</h3>

          <p class="text-gray-600 mb-8 leading-relaxed">
            Leverage our unrivaled Africa-wide talent network. Get matched with pre-vetted, qualified candidates who are ready to contribute to your
            team's success.
          </p>

          <a href="" class="inline-flex items-center px-6 py-3 bg-brand-black text-white rounded-xl hover:bg-brand-black/90 transition-colors">
            Tell me more
            <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </div>

        <!-- Promote Your Brand -->
        <div class="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100">
          <div class="bg-brand-black w-16 h-16 rounded-xl flex items-center justify-center mb-6">
            <img src="@/assets/icons/book.svg" alt="" class="w-8 h-8" />
          </div>

          <h3 class="text-xl md:text-2xl font-semibold text-brand-black mb-4">Promote Your Brand</h3>

          <p class="text-gray-600 mb-8 leading-relaxed">
            Utilize our media platforms to increase visibility of your brand to our community of professionals across Africa and reach the right
            audience effectively.
          </p>

          <a href="" class="inline-flex items-center px-6 py-3 bg-brand-black text-white rounded-xl hover:bg-brand-black/90 transition-colors">
            Show me how
            <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>

  <Steps />

  <WhoWeHelp />

  <div class="flex justify-center bg-gradient-to-b from-light-yellow to-white">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem]">
        <h2 class="mb-8 text-[30px] md:text-[40px] text-brand-black leading-[110%] font-semibold text-center">
          What Fast Growing Startups are saying about <br class="hidden md:inline-block" />
          <span class="text-brand-primary">CareerBuddy</span>
        </h2>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-10">
          <div class="bg-white rounded-2xl p-8 mb-6 md:mb-0 shadow-sm hover:shadow-md transition-all duration-300 flex flex-col justify-between">
            <p class="text-brand-black text-base leading-relaxed">
              "CareerBuddy helped us build a great team that has contributed to our success as a company. They took the time to understand our needs
              and provided us with candidates that were highly skilled and matched our company's culture. We are grateful for their partnership."
            </p>

            <div class="flex items-center mt-6">
              <div class="bg-light-yellow/10 rounded-lg p-1">
                <img src="@/assets/images/moniepoint2.png" class="w-[24px] h-[24px]" alt="Moniepoint" />
              </div>
              <h3 class="font-medium text-[16px] ml-2.5">Moniepoint</h3>
            </div>
          </div>

          <div class="bg-white rounded-2xl p-8 mb-6 md:mb-0 shadow-sm hover:shadow-md transition-all duration-300 flex flex-col justify-between">
            <p class="text-brand-black text-base leading-relaxed">
              "CareerBuddy is the go-to partner for startup hiring. Their team helped us navigate the complex process of hiring top talent and
              provided us with candidates that exceeded our expectations. They made the recruitment process seamless, and we would highly recommend
              them to any startup looking to build a great team."
            </p>

            <div class="flex items-center mt-6">
              <div class="bg-light-yellow/10 rounded-lg p-1">
                <img src="@/assets/images/ehealth.png" class="w-[24px] h-[24px]" alt="Ehealth Africa" />
              </div>
              <h3 class="font-medium text-[16px] ml-2.5">Ehealth Africa</h3>
            </div>
          </div>

          <div class="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-all duration-300 flex flex-col justify-between">
            <p class="text-brand-black text-base leading-relaxed">
              Working with CareerBuddy was a game-changer for us. Their team helped us navigate the complex process of hiring top talent and provided
              us with candidates that exceeded our expectations.
            </p>

            <div class="flex items-center mt-6">
              <div class="bg-light-yellow/10 rounded-lg p-1">
                <img src="@/assets/images/gamp.png" class="w-[24px] h-[24px]" alt="Gamp" />
              </div>
              <h3 class="font-medium text-[16px] ml-2.5">Gamp</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Customers from '@/components/partials/Customers.vue';
import Hero from '@/components/partials/Hero.vue';
import Steps from '@/components/partials/Steps.vue';
import WhoWeHelp from '@/components/partials/WhoWeHelp.vue';
import { useMeta } from '@/utils/meta';

useMeta();
</script>
