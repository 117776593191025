<template>
  <div class="animate-pulse">
    <div class="space-y-8">
      <!-- Featured Article Skeleton -->
      <div class="w-full">
        <div class="bg-gray-200 aspect-[16/9] rounded-xl mb-4"></div>
        <div class="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
        <div class="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
        <div class="h-4 bg-gray-200 rounded w-1/4"></div>
      </div>

      <!-- Grid Articles Skeleton -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div v-for="n in 3" :key="n" class="space-y-4">
          <div class="bg-gray-200 aspect-[4/3] rounded-xl"></div>
          <div class="h-6 bg-gray-200 rounded w-3/4"></div>
          <div class="h-4 bg-gray-200 rounded w-1/2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>
