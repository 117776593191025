<template>
  <Hero
    heroTitle="Create resumes <br class='hidden xl:inline-block' /> that get you hired"
    heroDesc="Say Goodbye to Job Search Frustrations. Resumes so Compelling, They're Irresistible. Achieve more with a resume that displays your strengths, works with recruiting software, and earns you interviews."
    heroBtnText="Get a new CV"
    heroBtnLink="#packages"
    heroImg="hero11.png"
  />

  <Customers />

  <div class="relative bg-gradient-to-br from-white via-light-yellow/5 to-white py-20">
    <!-- Decorative Elements -->
    <div class="absolute top-0 right-0 w-96 h-96 bg-light-yellow/10 rounded-full filter blur-3xl"></div>
    <div class="absolute bottom-0 left-0 w-96 h-96 bg-light-yellow/10 rounded-full filter blur-3xl"></div>

    <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Header Section -->
      <div class="text-center max-w-3xl mx-auto mb-20">
        <h1 class="text-xl md:text-4xl font-bold text-brand-black mb-2">Craft Your Perfect Resume</h1>
        <p class="text-xl md:text-lg text-gray-600 leading-relaxed">
          Stand out from the crowd with professionally crafted resumes that showcase your unique value proposition
        </p>
      </div>

      <!-- Features Grid -->
      <div class="grid md:grid-cols-3 gap-8">
        <!-- Feature 1 -->
        <div class="group">
          <div class="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-500">
            <div class="mb-6">
              <div
                class="w-16 h-16 bg-light-yellow/20 rounded-xl flex items-center justify-center transform transition-transform group-hover:rotate-6 duration-500"
              >
                <img src="@/assets/icons/users.svg" class="w-8 h-8" alt="Users Icon" />
              </div>
            </div>
            <h3 class="text-xl font-bold text-brand-black mb-4">Expert Resume Analysis</h3>
            <p class="text-gray-600 leading-relaxed">
              Our AI-powered system analyzes your current resume and identifies key areas for improvement to maximize your interview chances.
            </p>
          </div>
        </div>

        <!-- Feature 2 -->
        <div class="group">
          <div class="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-500">
            <div class="mb-6">
              <div
                class="w-16 h-16 bg-light-yellow/20 rounded-xl flex items-center justify-center transform transition-transform group-hover:rotate-6 duration-500"
              >
                <img src="@/assets/icons/message.svg" class="w-8 h-8" alt="Message Icon" />
              </div>
            </div>
            <h3 class="text-xl font-bold text-brand-black mb-4">Professional Writing Team</h3>
            <p class="text-gray-600 leading-relaxed">
              Our certified resume writers combine industry expertise with your unique story to create compelling career narratives.
            </p>
          </div>
        </div>

        <!-- Feature 3 -->
        <div class="group">
          <div class="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-500">
            <div class="mb-6">
              <div
                class="w-16 h-16 bg-light-yellow/20 rounded-xl flex items-center justify-center transform transition-transform group-hover:rotate-6 duration-500"
              >
                <img src="@/assets/icons/document.svg" class="w-8 h-8" alt="Document Icon" />
              </div>
            </div>
            <h3 class="text-xl font-bold text-brand-black mb-4">ATS-Optimized Format</h3>
            <p class="text-gray-600 leading-relaxed">
              Get past automated screening systems with our ATS-friendly templates and keyword optimization strategies.
            </p>
          </div>
        </div>
      </div>

      <!-- CTA Button -->
      <div class="text-center mt-16">
        <a
          href="#"
          class="inline-flex items-center px-8 py-4 bg-brand-black text-white font-semibold rounded-xl hover:bg-brand-black/90 transition-all duration-300"
        >
          Start Your Professional Resume
          <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        </a>
      </div>
    </div>
  </div>

  <div class="bg-[#060505] text-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <div class="grid md:grid-cols-2 gap-12 items-center">
        <!-- Content Section -->
        <div class="space-y-8">
          <div>
            <h2 class="text-3xl md:text-4xl font-bold mb-6">Get found on LinkedIn</h2>
            <p class="text-lg leading-relaxed text-gray-300 mb-8">
              Leverage the Power of LinkedIn to advance your Career with our Expert Profile Writing Service. Grow your network and get noticed by more
              employers with our professional LinkedIn strategies.
            </p>
          </div>

          <ul class="space-y-4">
            <li class="flex items-center text-gray-300/80 text-lg">
              <div class="w-6 h-6 mr-4 flex-shrink-0 bg-white/10 rounded-full flex items-center justify-center">
                <img src="@/assets/icons/check-sm.svg" class="w-4 h-4" alt="" />
              </div>
              Maximize your Visibility
            </li>
            <li class="flex items-center text-gray-300/80 text-lg">
              <div class="w-6 h-6 mr-4 flex-shrink-0 bg-white/10 rounded-full flex items-center justify-center">
                <img src="@/assets/icons/check-sm.svg" class="w-4 h-4" alt="" />
              </div>
              Showcase your Expertise
            </li>
            <li class="flex items-center text-gray-300/80 text-lg">
              <div class="w-6 h-6 mr-4 flex-shrink-0 bg-white/10 rounded-full flex items-center justify-center">
                <img src="@/assets/icons/check-sm.svg" class="w-4 h-4" alt="" />
              </div>
              Build your Personal Brand
            </li>
            <li class="flex items-center text-gray-300/80 text-lg">
              <div class="w-6 h-6 mr-4 flex-shrink-0 bg-white/10 rounded-full flex items-center justify-center">
                <img src="@/assets/icons/check-sm.svg" class="w-4 h-4" alt="" />
              </div>
              Stay ahead of the Competition
            </li>
            <li class="flex items-center text-gray-300/80 text-lg">
              <div class="w-6 h-6 mr-4 flex-shrink-0 bg-white/10 rounded-full flex items-center justify-center">
                <img src="@/assets/icons/check-sm.svg" class="w-4 h-4" alt="" />
              </div>
              Make Lasting Connections
            </li>
          </ul>

          <div class="pt-4">
            <a
              href="https://paystack.com/buy/linkedinoptimization"
              target="_blank"
              class="inline-block bg-white hover:bg-gray-100 text-brand-black text-lg font-semibold px-8 py-4 rounded-xl transition-colors duration-300"
            >
              Get Started
            </a>
          </div>
        </div>

        <!-- Image Section -->
        <div class="relative">
          <div class="absolute inset-0 bg-gradient-to-t from-[#060505] via-transparent to-transparent md:hidden"></div>
          <img src="@/assets/images/chrome.png" alt="LinkedIn Profile Preview" class="w-full rounded-lg shadow-2xl" />
        </div>
      </div>
    </div>
  </div>

  <div class="bg-light-yellow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <!-- Header Section -->
      <div class="max-w-4xl mx-auto text-center mb-16">
        <h2 class="text-xl md:text-4xl font-bold text-brand-black mb-2">Why our customers love our resume writing service</h2>
        <p class="text-lg md:text-lg text-gray-600/90 leading-relaxed">
          Our track record speaks for itself. Our resume writing service has helped countless individuals secure interviews and land their dream jobs,
          giving our customers the confidence and edge they need.
        </p>
      </div>

      <!-- Testimonial Grid -->
      <div class="grid md:grid-cols-2 gap-12 items-center">
        <!-- Content Side -->
        <div class="space-y-8">
          <img src="@/assets/images/complogo.png" alt="Company Logo" class="h-12 w-auto" />

          <blockquote class="relative">
            <div class="relative z-10">
              <p class="text-xl md:text-2xl text-gray-900 leading-relaxed mb-8">
                "I was initially skeptical, but Careerbuddy completely overhauled my CV, making it crisp, professional, and, above all, reflective of
                me! I've now landed my dream job at Jumia, and it's all thanks to the amazing team at Careerbuddy."
              </p>

              <footer class="mt-4">
                <div class="font-medium text-lg text-brand-black">Kwame Acheampong</div>
                <div class="text-gray-600">Jumia</div>
              </footer>
            </div>
          </blockquote>

          <!-- Navigation -->
          <div class="flex items-center gap-6 pt-8">
            <div class="flex gap-3">
              <button class="p-2 rounded-full hover:bg-black/5 transition-colors duration-200">
                <img src="@/assets/icons/nav_left.png" alt="Previous" class="w-6 h-6" />
              </button>
              <button class="p-2 rounded-full hover:bg-black/5 transition-colors duration-200">
                <img src="@/assets/icons/nav_right.png" alt="Next" class="w-6 h-6" />
              </button>
            </div>
            <div class="h-px bg-gray-200 flex-grow"></div>
          </div>
        </div>

        <!-- Image Side -->
        <div class="relative">
          <div class="aspect-[4/5] rounded-2xl overflow-hidden shadow-2xl">
            <img src="@/assets/images/woman_in_dress.png" alt="Professional woman in dress" class="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Pricing id="packages" />

  <faqs :faqs="faqs" />

  <div class="bg-gradient-to-b from-white to-gray-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="py-16 md:py-24">
        <!-- Newsletter Content -->
        <div class="max-w-4xl mx-auto text-center">
          <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold text-brand-black mb-6">Subscribe to our newsletter</h2>

          <p class="text-lg md:text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
            Get to know about top open jobs in Africa's fastest-growing startups before anyone else. Every week, we'll send you all the top jobs in
            startups across Africa.
          </p>

          <!-- Subscription Box -->
          <div class="bg-white rounded-2xl shadow-xl p-8 md:p-10 max-w-2xl mx-auto">
            <div class="flex flex-col items-center">
              <!-- Substack Integration -->
              <div class="w-full max-w-md">
                <iframe
                  src="https://careerbuddynewsletter.substack.com/embed"
                  width="100%"
                  height="170"
                  class="border border-gray-100 rounded-xl bg-white"
                  style="border-radius: 12px; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05)"
                  frameborder="0"
                  scrolling="no"
                ></iframe>
              </div>

              <!-- Stats -->
              <div class="mt-8 pt-8 border-t border-gray-100 w-full">
                <div class="flex justify-center space-x-12">
                  <div class="text-center">
                    <div class="text-lg font-bold text-brand-black">52,000+</div>
                    <div class="text-xs text-gray-600">Subscribers</div>
                  </div>
                  <div class="text-center">
                    <div class="text-lg font-bold text-brand-black">Weekly</div>
                    <div class="text-xs text-gray-600">Updates</div>
                  </div>
                  <div class="text-center">
                    <div class="text-lg font-bold text-brand-black">100%</div>
                    <div class="text-xs text-gray-600">Free</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Hero from '@/components/partials/Hero.vue';
import Customers from '@/components/partials/Customers.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import Faqs from '@/components/partials/Faqs.vue';
import Pricing from '@/components/Pricing.vue';
import { useMeta } from '@/utils/meta';

const faqs = ref([
  {
    title: 'How long does it take?',
    desc: `<p>2-3 days to receive the first review not final draft</p>`,
  },
  {
    title: 'Can I discuss a review after delivery?',
    desc: `<p>Yes, we will review your CV with you until you are completely satisfied.</p>`,
  },
  {
    title: 'What is your refund policy?',
    desc: `<p>All sales are final, but there will be multiple drafts and revisions to ensure your satisfaction. Careerbuddy CV writers will work with you throughout the revision period until you are satisfied with your CV.</p>`,
  },
  {
    title: 'Do I really need a LinkedIn?',
    desc: `<p>In a short answer - YES, if you are in the market for a job, it is highly recommended that you have a strong LinkedIn presence. Recruiters are avtively using LinkedIn to search for candidates with their skill set every single day.</p>`,
  },
]);
useMeta();
</script>
