<template>
  <div class="bg-[#1A1A1A]">
    <div class="w-full md:w-[82vw] mx-auto px-[24px] py-[42px] md:py-[60px] md:px-0 flex justify-between">
      <div class="w-full">
        <div class="w-full flex justify-between flex-wrap items-center">
          <router-link to="/">
            <img class="h8 w-[185px] lg:block" src="@/assets/images/logo-white.svg" alt="Your Company" />
          </router-link>
          <!-- <span class="text-white text-[18px]">Find Work that Works for you</span> -->
        </div>

        <!-- <div class="h-[1px] w-full bg-[#D9DBE9]/50 my-[49px]"></div> -->

        <div class="block md:flex justify-between flex-wrap gap-x-8 xl:flex-nowrap py-[57px]">
          <div class="mb-6 md:mr-3">
            <p class="text-white mb-[26px] text-base font-medium">Get Started</p>
            <ul class="space-y-[18px]">
              <li><router-link to="/talents" class="text-[#ADADAD] text-base font-normal">Talents Sign in</router-link></li>
              <li><router-link to="/talent" class="text-[#ADADAD] text-base font-normal">Talent Sign up</router-link></li>
              <li><router-link to="/employer" class="text-[#ADADAD] text-base font-normal">Employer Sign in</router-link></li>
              <li><router-link to="/employer" class="text-[#ADADAD] text-base font-normal">Employer Sign up</router-link></li>
            </ul>
          </div>
          <div class="mb-6 md:mr-3">
            <p class="text-white mb-[26px] text-base font-medium">For Company</p>
            <ul class="space-y-[18px]">
              <li><router-link to="/outsourcing" class="text-[#ADADAD] text-base font-normal">Head Hunting service</router-link></li>
              <li><router-link to="/outsourcing" class="text-[#ADADAD] text-base font-normal">Outsourcing & Remote Hiring</router-link></li>
              <li><router-link to="/talent-marketplace" class="text-[#ADADAD] text-base font-normal">Talent Marketplace</router-link></li>
              <li><router-link to="/embedded-recruiter" class="text-[#ADADAD] text-base font-normal">Embedded Recruiter</router-link></li>
              <li><router-link to="/employer-branding" class="text-[#ADADAD] text-base font-normal">Employer Branding</router-link></li>
            </ul>
          </div>
          <div class="mb-6 md:mr-3">
            <p class="text-white mb-[26px] text-base font-medium">For Talents</p>
            <ul class="space-y-[18px]">
              <li><router-link to="/talents" class="text-[#ADADAD] text-base font-normal">Find your next job</router-link></li>
              <li><router-link to="/jobs" class="text-[#ADADAD] text-base font-normal">Open Jobs</router-link></li>
              <li><router-link to="/cv" class="text-[#ADADAD] text-base font-normal">Write your CV</router-link></li>
              <li><a to="https://saalari.com/#/" class="text-[#ADADAD] text-base font-normal">Salary Data</a></li>
              <li><router-link to="/" class="text-[#ADADAD] text-base font-normal">Company Reviews</router-link></li>
              <li><router-link to="/" class="text-[#ADADAD] text-base font-normal">Refer a Friend</router-link></li>
            </ul>
          </div>
          <div class="mb-6 md:mr-3">
            <p class="text-white mb-[26px] text-base font-medium">Why CareerBuddy</p>
            <ul class="space-y-[18px]">
              <li><router-link to="/" class="text-[#ADADAD] text-base font-normal">About US</router-link></li>
              <li><a href="https://blog.thecareerbuddy.com/" class="text-[#ADADAD] text-base font-normal">Blog</a></li>
              <li><a href="#" class="text-[#ADADAD] text-base font-normal">Resources</a></li>
              <!-- <li><a href="/pricing" class="text-[#ADADAD] text-base font-normal">Pricing</a></li> -->
              <li><a href="https://support.thecareerbuddy.com/" class="text-[#ADADAD] text-base font-normal">FAQ</a></li>
            </ul>
          </div>
          <!-- <div class="mb-6 md:mr-3">
            <p class="text-white mb-[26px] text-base font-medium">Hire Talents</p>
            <ul class="space-y-[18px]">
              <li><router-link to="/hire/sales-and-marketing" class="text-[#ADADAD] text-base font-normal">Sales & Marketing</router-link></li>
              <li>
                <router-link to="/hire/engineering-data-technology" class="text-[#ADADAD] text-base font-normal"
                  >Engineering, Data & Technology</router-link
                >
              </li>
              <li><router-link to="/hire/finance-and-accounting" class="text-[#ADADAD] text-base font-normal">Finance & Accounting</router-link></li>
              <li><router-link to="/hire/support" class="text-[#ADADAD] text-base font-normal">Customer Support, Ops</router-link></li>
            </ul>
          </div> -->
          <!-- <div class="mb-6 md:mr-3">
            <p class="text-white mb-[26px] text-base font-medium">Login</p>
            <ul class="space-y-[18px]">
              <li><a :href="emp_link" class="text-[#ADADAD] text-base font-normal">Company Login</a></li>
              <li><a :href="candidate_link" class="text-[#ADADAD] text-base font-normal">Talent Login</a></li>
            </ul>
          </div> -->
          <div class="w-full md:w-[350px]">
            <p class="text-white text-base font-normal">Subscribe to our newsletter</p>
            <p class="text-white/50 py-6">Stay Informed: Subscribe to Our Newsletter</p>
            <iframe
              src="https://careerbuddynewsletter.substack.com/embed"
              width="320"
              height="150"
              style="border: 1px solid #eee; background: white"
              frameborder="0"
              scrolling="no"
            ></iframe>
          </div>
        </div>

        <div class="h-[1px] w-full bg-[#D9DBE9]/50 my-[35px]"></div>

        <div class="flex justify-between gap-8 items-center flex-wrap mb8">
          <div class="gap-4 flex items-center flex-wrap">
            <p class="text-white">Copyright &copy; {{ date }} | All Rights Reserved</p>
            <router-link to="/" class="text-white"> Terms of use </router-link>
            <router-link to="/" class="text-white"> Privacy </router-link>
          </div>

          <div class="inline-flex items-center gap-x-4">
            <a href="https://www.facebook.com/careerbuddyafrica/">
              <img src="@/assets/icons/facebook.svg" class="w-[11px] h-[19px]" alt="facebook" />
            </a>
            <a href="https://twitter.com/careerbuddyhq">
              <img src="@/assets/icons/twitter.svg" class="w-[19px] h-[15px]" alt="x" />
            </a>
            <a href="https://www.instagram.com/careerbuddyhq/">
              <img src="@/assets/icons/instagram.svg" class="w-[19px] h-[19px]" alt="instagram" />
            </a>
            <a href="https://www.linkedin.com/company/thecareerbuddy">
              <img src="@/assets/icons/linkedin.svg" class="w-[19px] h-[18px]" alt="linkedin" />
            </a>
            <a href="https://www.youtube.com/@careerbuddyhq">
              <img src="@/assets/icons/youtube.svg" class="w-[21px] h-[15px]" alt="youtube" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const candidate_link = computed(() => process.env.VUE_APP_CANDIDATE_URL);

const emp_link = computed(() => process.env.VUE_APP_EMPLOYER_URL);

const date = ref(new Date().getFullYear());
</script>
