import { createClient } from 'contentful';

const contentfulClient = createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
});

console.log(contentfulClient);

export const getBlogPosts = async () => {
  try {
    const entries = await contentfulClient.getEntries({
      content_type: 'blogs',
      order: '-sys.createdAt',
    });

    return entries.items.map((item) => ({
      id: item.sys.id,
      title: item.fields.title || '',
      slug: item.fields.slug || '',
      description: item.fields.description || '',
      content: item.fields.content || '',
      category: item.fields.category || '',
      featuredImage: item.fields.featuredImage?.fields?.file?.url ? `https:${item.fields.featuredImage.fields.file.url}` : '',
      author: {
        name: item.fields.author?.fields?.name || 'Anonymous',
        avatar: item.fields.author?.fields?.avatar?.fields?.file?.url
          ? `https:${item.fields.author.fields.avatar.fields.file.url}`
          : `https://ui-avatars.com/api/?name=${item.fields.author?.fields?.name || 'Anonymous'}`,
      },
      publishDate: item.fields.publishedDate || item.sys.createdAt,
      isFeatured: item.fields.isFeatured || false,
    }));
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    return [];
  }
};

export const getFeaturedPosts = async () => {
  try {
    const entries = await contentfulClient.getEntries({
      content_type: 'blogs',
      'fields.isFeatured': true,
      order: '-sys.createdAt',
    });

    return entries.items.map((item) => ({
      id: item.sys.id,
      title: item.fields.title || '',
      slug: item.fields.slug || '',
      description: item.fields.description || '',
      category: item.fields.category || '',
      featuredImage: item.fields.featuredImage?.fields?.file?.url ? `https:${item.fields.featuredImage.fields.file.url}` : '',
      author: {
        name: item.fields.author?.fields?.name || 'Anonymous',
        avatar: item.fields.author?.fields?.avatar?.fields?.file?.url
          ? `https:${item.fields.author.fields.avatar.fields.file.url}`
          : `https://ui-avatars.com/api/?name=${item.fields.author?.fields?.name || 'Anonymous'}`,
      },
      publishDate: item.fields.publishedDate || item.sys.createdAt,
    }));
  } catch (error) {
    console.error('Error fetching featured posts:', error);
    return [];
  }
};

export const getBlogPostBySlug = async (slug) => {
  try {
    const entries = await contentfulClient.getEntries({
      content_type: 'blogs',
      'fields.slug': slug,
    });

    if (entries.items.length === 0) {
      return null;
    }

    const item = entries.items[0];
    return {
      id: item.sys.id,
      title: item.fields.title || '',
      slug: item.fields.slug,
      description: item.fields.description || '',
      content: item.fields.content || '',
      category: item.fields.category || '',
      featuredImage: item.fields.featuredImage?.fields?.file?.url ? `https:${item.fields.featuredImage.fields.file.url}` : '',
      author: {
        name: item.fields.author?.fields?.name || 'Anonymous',
        avatar: item.fields.author?.fields?.avatar?.fields?.file?.url
          ? `https:${item.fields.author.fields.avatar.fields.file.url}`
          : `https://ui-avatars.com/api/?name=${item.fields.author?.fields?.name || 'Anonymous'}`,
      },
      publishDate: item.fields.publishedDate || item.sys.createdAt,
      isFeatured: item.fields.isFeatured || false,
    };
  } catch (error) {
    console.error('Error fetching blog post:', error);
    return null;
  }
};
