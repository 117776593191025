<template>
  <Hero
    heroTitle="The right job can change everything"
    heroDesc="At CareerBuddy, we utilize a unique combination of matching technology and human empathy to connect you with the right opportunity."
    heroBtnText="Find your Next Job"
    heroImg="candidate-top.png"
    heroBtnLink="/jobs"
  />

  <div style="background: linear-gradient(180deg, #fef7f1 0%, #ffffff 100%)" class="py-20">
    <div class="w-full px-[24px] md:px-0 md:w-[82vw] mx-auto">
      <h2 class="mb-12 text-center text-[16px] md:text-[28px] font-semibold text-gray-900">
        Companies where our talents work
        <!-- <div class="mt-2 mx-auto w-20 h-1 bg-brand-spot rounded-full"></div> -->
      </h2>

      <div class="relative overflow-hidden py-4">
        <!-- Gradient Overlays -->
        <div
          class="absolute left-0 top-0 bottom-0 w-32 z-10"
          style="background: linear-gradient(90deg, #fef7f1 0%, rgba(254, 247, 241, 0) 100%)"
        ></div>
        <div
          class="absolute right-0 top-0 bottom-0 w-32 z-10"
          style="background: linear-gradient(270deg, #fef7f1 0%, rgba(254, 247, 241, 0) 100%)"
        ></div>

        <!-- Scrolling Container -->
        <div class="logos-slide-track flex items-center space-x-24 md:space-x-32 animate-scroll">
          <div v-for="i in 2" :key="i" class="flex items-center space-x-24 md:space-x-32">
            <div class="flex-shrink-0 w-[120px] md:w-[160px] flex items-center justify-center">
              <img
                src="@/assets/images/airbnb-logo.png"
                class="h-10 md:h-12 object-contain grayscale hover:grayscale-0 transition-all duration-300"
                alt="Airbnb"
              />
            </div>
            <div class="flex-shrink-0 w-[120px] md:w-[160px] flex items-center justify-center">
              <img
                src="@/assets/images/first-bank-logo.svg"
                class="h-10 md:h-12 object-contain grayscale hover:grayscale-0 transition-all duration-300"
                alt="First Bank"
              />
            </div>
            <div class="flex-shrink-0 w-[120px] md:w-[160px] flex items-center justify-center">
              <img
                src="@/assets/images/piggy-png.png"
                class="h-10 md:h-12 object-contain grayscale hover:grayscale-0 transition-all duration-300"
                alt="Piggyvest"
              />
            </div>
            <div class="flex-shrink-0 w-[120px] md:w-[160px] flex items-center justify-center">
              <img
                src="@/assets/images/paystack-logo.svg"
                class="h-10 md:h-12 object-contain grayscale hover:grayscale-0 transition-all duration-300"
                alt="Paystack"
              />
            </div>
            <div class="flex-shrink-0 w-[120px] md:w-[160px] flex items-center justify-center">
              <img
                src="@/assets/images/target-logo.png"
                class="h-8 md:h-10 object-contain grayscale hover:grayscale-0 transition-all duration-300"
                alt="Target"
              />
            </div>
            <div class="flex-shrink-0 w-[120px] md:w-[160px] flex items-center justify-center">
              <img
                src="@/assets/images/Flutterwave-logo.png"
                class="h-10 md:h-12 object-contain grayscale hover:grayscale-0 transition-all duration-300"
                alt="Flutterwave"
              />
            </div>
            <div class="flex-shrink-0 w-[120px] md:w-[160px] flex items-center justify-center">
              <img
                src="@/assets/images/apt.png"
                class="h-10 md:h-12 object-contain grayscale hover:grayscale-0 transition-all duration-300"
                alt="Apt"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="background: linear-gradient(135deg, #111827 0%, #1f2937 50%, #111827 100%)" class="w-full py-24">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-20">
        <h2 class="text-4xl md:text-5xl font-bold text-white mb-6">How do we serve you?</h2>
        <div
          style="background: linear-gradient(90deg, #f5a524 0%, rgba(245, 165, 36, 0.8) 50%, transparent 100%)"
          class="w-24 h-1.5 mx-auto rounded-full"
        ></div>
      </div>

      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <!-- Card 1 -->
        <div
          class="group relative backdrop-blur-xl rounded-2xl p-8 transition-all duration-500 overflow-hidden"
          style="background: rgba(255, 255, 255, 0.03); border: 1px solid rgba(255, 255, 255, 0.05)"
        >
          <!-- Decorative elements -->
          <div
            class="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500"
            style="background: linear-gradient(135deg, rgba(245, 165, 36, 0.1) 0%, transparent 50%, transparent 100%)"
          ></div>
          <div
            class="absolute -inset-0.5 opacity-0 group-hover:opacity-10 blur-xl transition-opacity duration-500"
            style="background: linear-gradient(90deg, rgba(245, 165, 36, 0.3) 0%, transparent 100%)"
          ></div>

          <div class="relative">
            <!-- Header -->
            <div class="flex items-center space-x-4 mb-8">
              <span class="text-brand-spot text-5xl font-bold leading-none">01</span>
              <div class="h-0.5 flex-grow bg-gradient-to-r from-brand-spot/50 to-transparent rounded-full"></div>
            </div>

            <!-- Content -->
            <div class="min-h-[320px] flex flex-col">
              <h3 class="text-2xl font-bold text-white mb-6">Create a profile. Get great matches</h3>
              <p class="text-gray-300/90 leading-relaxed mb-8 flex-grow">
                Tell us about your background and what you look for in a job. Our matching algorithms ensure you find positions that align with your
                skills and preferences.
              </p>

              <!-- Features list -->
              <ul class="space-y-5">
                <li class="flex items-center space-x-4 group-hover:translate-x-2 transition-transform duration-300">
                  <span
                    class="flex-shrink-0 w-10 h-10 rounded-xl bg-gradient-to-br from-brand-spot/20 to-brand-spot/5 flex items-center justify-center border border-brand-spot/20"
                  >
                    <div class="w-2 h-2 rounded-full bg-brand-spot"></div>
                  </span>
                  <span class="text-brand-spot font-medium">Showcase your skills and passions</span>
                </li>
                <li class="flex items-center space-x-4 group-hover:translate-x-2 transition-transform duration-300 delay-75">
                  <span
                    class="flex-shrink-0 w-10 h-10 rounded-xl bg-gradient-to-br from-brand-spot/20 to-brand-spot/5 flex items-center justify-center border border-brand-spot/20"
                  >
                    <div class="w-2 h-2 rounded-full bg-brand-spot"></div>
                  </span>
                  <span class="text-brand-spot font-medium">Personalize with projects and reviews</span>
                </li>
                <li class="flex items-center space-x-4 group-hover:translate-x-2 transition-transform duration-300 delay-150">
                  <span
                    class="flex-shrink-0 w-10 h-10 rounded-xl bg-gradient-to-br from-brand-spot/20 to-brand-spot/5 flex items-center justify-center border border-brand-spot/20"
                  >
                    <div class="w-2 h-2 rounded-full bg-brand-spot"></div>
                  </span>
                  <span class="text-brand-spot font-medium">Private from current employers</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Card 2 -->
        <div
          class="group relative backdrop-blur-xl rounded-2xl p-8 transition-all duration-500 overflow-hidden"
          style="background: rgba(255, 255, 255, 0.03); border: 1px solid rgba(255, 255, 255, 0.05)"
        >
          <!-- Decorative elements -->
          <div
            class="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500"
            style="background: linear-gradient(135deg, rgba(52, 211, 153, 0.1) 0%, transparent 50%, transparent 100%)"
          ></div>
          <div
            class="absolute -inset-0.5 opacity-0 group-hover:opacity-10 blur-xl transition-opacity duration-500"
            style="background: linear-gradient(90deg, rgba(52, 211, 153, 0.3) 0%, transparent 100%)"
          ></div>

          <div class="relative">
            <!-- Header -->
            <div class="flex items-center space-x-4 mb-8">
              <span class="text-emerald-400 text-5xl font-bold leading-none">02</span>
              <div class="h-0.5 flex-grow bg-gradient-to-r from-emerald-400/50 to-transparent rounded-full"></div>
            </div>

            <!-- Content -->
            <div class="min-h-[320px] flex flex-col">
              <h3 class="text-2xl font-bold text-white mb-6">Companies apply to you</h3>
              <p class="text-gray-300/90 leading-relaxed mb-8 flex-grow">
                Your profile gets promoted to employers for two weeks. When we find a potential match, employers can view your profile and request an
                interview.
              </p>

              <!-- Features list -->
              <ul class="space-y-5">
                <li class="flex items-center space-x-4 group-hover:translate-x-2 transition-transform duration-300">
                  <span
                    class="flex-shrink-0 w-10 h-10 rounded-xl bg-gradient-to-br from-emerald-400/20 to-emerald-400/5 flex items-center justify-center border border-emerald-400/20"
                  >
                    <div class="w-2 h-2 rounded-full bg-emerald-400"></div>
                  </span>
                  <span class="text-emerald-400 font-medium">Skip applications and cover letters</span>
                </li>
                <li class="flex items-center space-x-4 group-hover:translate-x-2 transition-transform duration-300 delay-75">
                  <span
                    class="flex-shrink-0 w-10 h-10 rounded-xl bg-gradient-to-br from-emerald-400/20 to-emerald-400/5 flex items-center justify-center border border-emerald-400/20"
                  >
                    <div class="w-2 h-2 rounded-full bg-emerald-400"></div>
                  </span>
                  <span class="text-emerald-400 font-medium">Get upfront salary information</span>
                </li>
                <li class="flex items-center space-x-4 group-hover:translate-x-2 transition-transform duration-300 delay-150">
                  <span
                    class="flex-shrink-0 w-10 h-10 rounded-xl bg-gradient-to-br from-emerald-400/20 to-emerald-400/5 flex items-center justify-center border border-emerald-400/20"
                  >
                    <div class="w-2 h-2 rounded-full bg-emerald-400"></div>
                  </span>
                  <span class="text-emerald-400 font-medium">Hear from matching employers</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Card 3 -->
        <div
          class="group relative backdrop-blur-xl rounded-2xl p-8 transition-all duration-500 overflow-hidden"
          style="background: rgba(255, 255, 255, 0.03); border: 1px solid rgba(255, 255, 255, 0.05)"
        >
          <!-- Decorative elements -->
          <div
            class="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500"
            style="background: linear-gradient(135deg, rgba(96, 165, 250, 0.1) 0%, transparent 50%, transparent 100%)"
          ></div>
          <div
            class="absolute -inset-0.5 opacity-0 group-hover:opacity-10 blur-xl transition-opacity duration-500"
            style="background: linear-gradient(90deg, rgba(96, 165, 250, 0.3) 0%, transparent 100%)"
          ></div>

          <div class="relative">
            <!-- Header -->
            <div class="flex items-center space-x-4 mb-8">
              <span class="text-blue-400 text-5xl font-bold leading-none">03</span>
              <div class="h-0.5 flex-grow bg-gradient-to-r from-blue-400/50 to-transparent rounded-full"></div>
            </div>

            <!-- Content -->
            <div class="min-h-[320px] flex flex-col">
              <h3 class="text-2xl font-bold text-white mb-6">Choose, interview and accept</h3>
              <p class="text-gray-300/90 leading-relaxed mb-8 flex-grow">
                Take control of your career path. Review matching opportunities, schedule interviews at your convenience, and make informed decisions.
              </p>

              <!-- Features list -->
              <ul class="space-y-5">
                <li class="flex items-center space-x-4 group-hover:translate-x-2 transition-transform duration-300">
                  <span
                    class="flex-shrink-0 w-10 h-10 rounded-xl bg-gradient-to-br from-blue-400/20 to-blue-400/5 flex items-center justify-center border border-blue-400/20"
                  >
                    <div class="w-2 h-2 rounded-full bg-blue-400"></div>
                  </span>
                  <span class="text-blue-400 font-medium">Interview with exciting companies</span>
                </li>
                <li class="flex items-center space-x-4 group-hover:translate-x-2 transition-transform duration-300 delay-75">
                  <span
                    class="flex-shrink-0 w-10 h-10 rounded-xl bg-gradient-to-br from-blue-400/20 to-blue-400/5 flex items-center justify-center border border-blue-400/20"
                  >
                    <div class="w-2 h-2 rounded-full bg-blue-400"></div>
                  </span>
                  <span class="text-blue-400 font-medium">Get interview support</span>
                </li>
                <li class="flex items-center space-x-4 group-hover:translate-x-2 transition-transform duration-300 delay-150">
                  <span
                    class="flex-shrink-0 w-10 h-10 rounded-xl bg-gradient-to-br from-blue-400/20 to-blue-400/5 flex items-center justify-center border border-blue-400/20"
                  >
                    <div class="w-2 h-2 rounded-full bg-blue-400"></div>
                  </span>
                  <span class="text-blue-400 font-medium">Accept aligned opportunities</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="relative overflow-hidden bg-gradient-to-br from-white to-gray-50">
    <div class="absolute inset-0 bg-grid-black/[0.02] bg-[length:20px_20px]"></div>
    <div class="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-l from-brand-spot/5 to-transparent"></div>

    <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <div class="grid lg:grid-cols-2 gap-16 items-center">
        <!-- Image Section -->
        <div class="relative">
          <div class="relative w-full max-w-lg mx-auto">
            <!-- Main Image -->
            <img src="@/assets/images/man-plus.png" class="relative w-full h-auto object-cover mx-auto" alt="Career tracking illustration" />
          </div>
        </div>

        <!-- Content Section -->
        <div class="relative">
          <div class="space-y-6">
            <div class="inline-flex items-center space-x-2 px-3 py-1 rounded-full bg-brand-spot/10 text-brand-spot text-sm font-medium">
              <span class="flex-shrink-0 w-2 h-2 rounded-full bg-brand-spot"></span>
              <span>Real-time Tracking</span>
            </div>

            <h2 class="text-3xl md:text-4xl font-bold text-gray-900 leading-tight">Effectively Track real-time and update from team management.</h2>

            <p class="text-lg text-gray-600 leading-relaxed">
              Once you're accepted to CareerBuddy, companies reach out to directly to you. Our matching algorithm shows off your profile to hiring
              managers looking for someone with your skills, experience and preference.
            </p>

            <!-- Feature List -->
            <ul class="space-y-4 mt-8">
              <li class="flex items-center space-x-3 text-gray-700">
                <span class="flex-shrink-0 w-6 h-6 rounded-full bg-brand-spot/10 flex items-center justify-center">
                  <svg class="w-4 h-4 text-brand-spot" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span>Real-time profile visibility tracking</span>
              </li>
              <li class="flex items-center space-x-3 text-gray-700">
                <span class="flex-shrink-0 w-6 h-6 rounded-full bg-brand-spot/10 flex items-center justify-center">
                  <svg class="w-4 h-4 text-brand-spot" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span>Instant notifications for profile views</span>
              </li>
              <li class="flex items-center space-x-3 text-gray-700">
                <span class="flex-shrink-0 w-6 h-6 rounded-full bg-brand-spot/10 flex items-center justify-center">
                  <svg class="w-4 h-4 text-brand-spot" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span>Direct communication with hiring managers</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-gradient-to-b from-light-yellow to-light-yellow/50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <!-- Header Section -->
      <div class="max-w-3xl mx-auto text-center mb-16">
        <h2 class="text-4xl md:text-6xl font-bold text-brand-black mb-6 leading-[1.1]">Join a Verified Talentpool</h2>
        <p class="text-lg md:text-xl text-gray-600 leading-relaxed">
          Most hiring takes time and it's stressful. Join our Verified pool and get presented to multiple employers at once. The verification process
          is rigorous to make sure everyone accepted gets the best opportunities faster. We will promote your profile weekly to employers hiring.
        </p>
      </div>

      <!-- Cards Grid -->
      <div class="grid md:grid-cols-2 gap-8">
        <!-- Refer Friends Card -->
        <div class="bg-white rounded-3xl p-8 md:p-10 shadow-lg hover:shadow-xl transition-shadow duration-300">
          <div class="flex flex-col h-full">
            <div class="w-12 h-12 bg-brand-spot/10 rounded-full flex items-center justify-center">
              <svg class="w-8 h-8 text-brand-spot" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <div class="flex-grow">
              <h3 class="text-2xl md:text-3xl font-bold text-gray-900 mt-6 mb-4">Refer friends to career buddy</h3>
              <p class="text-gray-600 text-lg leading-relaxed">
                Help your friends and yourself. We'll send you a little extra cash for each friend who lands a job or makes a hire through us.
              </p>
            </div>
            <div class="mt-8">
              <a
                href="#"
                class="inline-flex items-center justify-center px-8 py-4 rounded-2xl bg-brand-black text-white font-medium hover:bg-brand-black/90 transition-colors duration-200"
              >
                Start Referring
              </a>
            </div>
          </div>
        </div>

        <!-- Get Ready Card -->
        <div class="bg-white rounded-3xl p-8 md:p-10 shadow-lg hover:shadow-xl transition-shadow duration-300">
          <div class="flex flex-col h-full">
            <div class="w-12 h-12 bg-brand-spot/10 rounded-full flex items-center justify-center">
              <svg class="w-8 h-8 text-brand-spot" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                />
              </svg>
            </div>
            <div class="flex-grow">
              <h3 class="text-2xl md:text-3xl font-bold text-gray-900 mt-6 mb-4">Get ready for greatness</h3>
              <p class="text-gray-600 text-lg leading-relaxed">
                Check out the CareerBuddy blog and our newsletters for tips, insights and stories around the world of work. Plus information on some
                of your favourite companies.
              </p>
            </div>
            <div class="mt-8">
              <a
                href="/blogs"
                class="inline-flex items-center justify-center px-8 py-4 rounded-2xl bg-brand-black text-white font-medium hover:bg-brand-black/90 transition-colors duration-200"
              >
                Read our Blog
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-brand-black py-24">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Section Header -->
      <div class="text-center mb-16">
        <h2 class="text-4xl md:text-6xl font-bold text-white mb-6">Hear what others are saying</h2>
        <div class="w-24 h-1 bg-brand-spot mx-auto"></div>
      </div>

      <!-- Testimonials Grid -->
      <div class="grid md:grid-cols-3 gap-8">
        <!-- Testimonial 1 -->
        <div class="bg-white rounded-3xl p-8 transform hover:-translate-y-1 transition-all duration-300">
          <div class="flex flex-col h-full">
            <div class="mb-6">
              <svg class="w-10 h-10 text-brand-spot" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"
                />
              </svg>
            </div>
            <div class="flex-grow">
              <p class="text-gray-600 text-lg leading-relaxed mb-6">
                CareerBuddy made the job hunting process so much easier for me. Their team was very responsive and kept me updated on any job
                opportunities that matched my skills and interests. They connected me with a startup that was the perfect fit and now I'm working in
                my dream job. Thank you, CareerBuddy!
              </p>
            </div>
            <div class="flex items-center">
              <div class="w-12 h-12 bg-brand-spot/10 rounded-full flex items-center justify-center mr-4">
                <span class="text-xl font-bold text-brand-spot">D</span>
              </div>
              <div>
                <h4 class="font-semibold text-gray-900">Damola</h4>
                <p class="text-sm text-gray-500">Software Engineer</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Testimonial 2 -->
        <div class="bg-white rounded-3xl p-8 transform hover:-translate-y-1 transition-all duration-300">
          <div class="flex flex-col h-full">
            <div class="mb-6">
              <svg class="w-10 h-10 text-brand-spot" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"
                />
              </svg>
            </div>
            <div class="flex-grow">
              <p class="text-gray-600 text-lg leading-relaxed mb-6">
                CareerBuddy helped me land a job with one of the best startups in Africa. Their team was extremely helpful throughout the entire
                process, from polishing my resume to preparing me for interviews. They were always available to answer any questions I had and made
                the entire process stress-free.
              </p>
            </div>
            <div class="flex items-center">
              <div class="w-12 h-12 bg-brand-spot/10 rounded-full flex items-center justify-center mr-4">
                <span class="text-xl font-bold text-brand-spot">B</span>
              </div>
              <div>
                <h4 class="font-semibold text-gray-900">Bolanle</h4>
                <p class="text-sm text-gray-500">Product Designer</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Testimonial 3 -->
        <div class="bg-white rounded-3xl p-8 transform hover:-translate-y-1 transition-all duration-300">
          <div class="flex flex-col h-full">
            <div class="mb-6">
              <svg class="w-10 h-10 text-brand-spot" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"
                />
              </svg>
            </div>
            <div class="flex-grow">
              <p class="text-gray-600 text-lg leading-relaxed mb-6">
                I couldn't be happier with my experience with CareerBuddy. They connected me with a great startup that was a fit for my skills and
                interests. Their team provided me with valuable insights and guidance throughout the hiring process, making everything seamless. I'm
                grateful for their support and expertise.
              </p>
            </div>
            <div class="flex items-center">
              <div class="w-12 h-12 bg-brand-spot/10 rounded-full flex items-center justify-center mr-4">
                <span class="text-xl font-bold text-brand-spot">N</span>
              </div>
              <div>
                <h4 class="font-semibold text-gray-900">Nkiru</h4>
                <p class="text-sm text-gray-500">Data Analyst</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-gradient-to-b from-white to-gray-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <div class="text-center max-w-4xl mx-auto">
        <!-- Decorative element -->
        <div class="flex justify-center mb-8">
          <div class="w-16 h-16 bg-brand-spot/10 rounded-full flex items-center justify-center">
            <svg class="w-8 h-8 text-brand-spot" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          </div>
        </div>

        <h2 class="text-4xl md:text-6xl font-bold text-brand-black mb-6 leading-[1.1]">Use our CV Writing Business</h2>

        <p class="text-xl md:text-2xl text-gray-600 leading-relaxed mb-12 max-w-3xl mx-auto">
          Use our CV Writing service to increase your chances of landing your next job. Speak to a career coach to help you find your next opportunity
          faster.
        </p>

        <router-link
          to="cv"
          class="inline-flex items-center justify-center px-8 py-4 rounded-2xl bg-brand-black text-white font-medium hover:bg-brand-black/90 transition-all duration-200 transform hover:-translate-y-1 text-lg"
        >
          Get your new CV
          <svg class="w-5 h-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </router-link>
      </div>
    </div>
  </div>

  <CreateAccount />
</template>

<script setup>
import CreateAccount from '@/components/partials/CreateAccount.vue';
import Hero from '@/components/partials/Hero.vue';
import { useMeta } from '@/utils/meta';

useMeta();
</script>

<style scoped>
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% - 2rem));
  }
}

.animate-scroll {
  animation: scroll 25s linear infinite;
}

.logos-slide-track:hover {
  animation-play-state: paused;
}
</style>
