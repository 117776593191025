<!-- RelatedPosts.vue -->
<template>
  <section class="mt-16 pt-8 border-t border-gray-200">
    <h2 class="text-2xl font-bold mb-8 text-gray-900">Related Articles</h2>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      <router-link v-for="post in relatedPosts" :key="post.id" :to="`/blog/${post.slug}`" class="group cursor-pointer">
        <div v-if="post.featuredImage" class="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden mb-4">
          <img
            :src="post.featuredImage"
            :alt="post.title"
            class="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div class="space-y-2">
          <span class="text-sm text-brand-spot font-medium">{{ post.category }}</span>
          <h3 class="font-bold text-gray-900 group-hover:text-brand-spot transition-colors">
            {{ post.title }}
          </h3>
          <p class="text-sm text-gray-600 line-clamp-2">{{ post.description }}</p>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  currentPost: {
    type: Object,
    required: true,
  },
  allPosts: {
    type: Array,
    required: true,
  },
});

const relatedPosts = computed(() => {
  return props.allPosts
    .filter(
      (post) =>
        post.id !== props.currentPost.id &&
        (post.category === props.currentPost.category || post.tags?.some((tag) => props.currentPost.tags?.includes(tag))),
    )
    .slice(0, 3);
});
</script>
